import { createPortal } from 'react-dom';
import {
	IEntityDetailPopupProps,
	ITabListProps,
	ITabListTabProps,
	EditMethod,
	entityTabType,
	IEntityDetailPopupConfiguredProps,
	INewEntityDetailPopupConfiguredProps,
	INewEntityDetailPopupProps,
	IEntityFormDetailProps,
} from './entityDetailPopup.interfaces';
import styles from './entityDetailPopup.module.scss';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { tabConfigurations } from './entityDetailPopup.tabConfigurations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openConfirm } from '../Dialog/dialogSlice';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { EntityType } from './entityDetailPopup.enums';
import { hasModule, moduleNames } from '../../utilities/authProvider';
import CompanyViewModel from '../../viewmodels/CompanyViewModel';

export function EntityDetailPopupConfigured(props: IEntityDetailPopupConfiguredProps | INewEntityDetailPopupConfiguredProps) {
	const tabConfig = tabConfigurations[props.popupEntityType];

	const viewModel = !tabConfig.new && !props.new ? tabConfig.viewModel(props.viewModelInstanceName) : undefined;

	return (
		<EntityDetailPopup
			viewModel={viewModel}
			id={props.id}
			parentId={props.parentId}
			editMethod={props.editMethod}
			popupEntityType={props.popupEntityType}
			closeFunction={props.closeFunction}
			defaultEditingState={props.defaultEditingState}
		/>
	);
}

export function EntityDetailPopup(props: IEntityDetailPopupProps | INewEntityDetailPopupProps) {
	const viewModel = !props.new ? props.viewModel : undefined;
	const [isDirty, setIsDirty] = useState(false);
	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const tabs = useMemo(() => {
		const copyTabs = [...tabConfigurations[props.popupEntityType].tabs];

		//remove Opportunity Tab when opportunity module is not enabled
		if (props.popupEntityType === EntityType.Company) {
			const hasOpportunityModule = hasModule(moduleNames.opportunity);
			if (!hasOpportunityModule) {
				const index = copyTabs.findIndex((tab) => {
					if (tab.type !== entityTabType.subEntityList) return false;
					else return tab.entityType === EntityType.Opportunity;
				});

				if (index !== -1) copyTabs.splice(index, 1);
			}
		}

		//remove Files Tab when document module is not enabled
		const hasDocManagementModule = hasModule(moduleNames.documentManagement);
		if (!hasDocManagementModule) {
			const index = copyTabs.findIndex((tab) => {
				if (tab.type !== entityTabType.subEntityList) return false;
				else return tab.entityType === EntityType.File;
			});

			if (index !== -1) copyTabs.splice(index, 1);
		}

		return copyTabs;
	}, [props.popupEntityType]);

	const [currentTabIndex, setCurrentTabIndex] = useState<number>(
		tabs.findIndex((tab) => tab.type === entityTabType.form || tab.type === entityTabType.subEntityList || tab.type === entityTabType.files)
	);

	const currentTab = currentTabIndex !== -1 ? tabs[currentTabIndex] : null;

	const [tabListProps] = useState<ITabListProps>({
		tabs: tabs.map((tab, index) => {
			const object: ITabListTabProps = {
				title: tab.title,
				icon: tab.icon,
			};

			if (tab.type === entityTabType.form || tab.type === entityTabType.subEntityList || tab.type === entityTabType.files)
				object.onClick = () => setCurrentTabIndex(index);

			return object;
		}),
	});

	const [visible, setVisible] = useState(true);

	let defaultEditingState = props.defaultEditingState !== undefined ? props.defaultEditingState : false;
	if (props.editMethod === EditMethod.create) defaultEditingState = true;

	const [isEditing, setIsEditing] = useState(defaultEditingState);

	const dispatch = useDispatch();

	const propsCloseFunction = props.closeFunction;
	const closeFunction = useCallback(
		(ignoreIsDirty?: boolean) => {
			if (viewModel) {
				if (viewModel.isDirty && !ignoreIsDirty) {
					dispatch(
						openConfirm({
							title: 'confirmUnsavedChanges',
							dismissButtonPosition: 'bottom',
							confirmButtons: [
								{
									onClick: () => propsCloseFunction(viewModel.hasSavedChanges),
									confirmText: 'closeAnyway',
								},
							],
						})
					);
					return;
				}

				propsCloseFunction(viewModel.hasSavedChanges);
			} else {
				if (isDirty && !ignoreIsDirty) {
					dispatch(
						openConfirm({
							title: 'confirmUnsavedChanges',
							dismissButtonPosition: 'bottom',
							confirmButtons: [
								{
									onClick: () => propsCloseFunction(hasSavedChanges),
									confirmText: 'closeAnyway',
								},
							],
						})
					);
					return;
				}

				propsCloseFunction(hasSavedChanges);
			}
		},
		[viewModel, propsCloseFunction, dispatch, isDirty, hasSavedChanges]
	);

	const getCurrentViewElement = useCallback(() => {
		if (!currentTab) return null;

		const elementProps: IEntityFormDetailProps = {
			id: props.id,
			parentId: props.parentId,
			editMethod: props.editMethod,
			isEditing,
			setIsEditing,
			setIsDirty,
			setHasSavedChanges,
			vm: viewModel ?? CompanyViewModel('placeHolder'),
			closeDetailPopup: closeFunction,
			defaultEditingState: props.defaultEditingState,
			crudCallbacks: props.crudCallbacks,
		};

		if (currentTab.type === entityTabType.form) {
			return React.createElement(currentTab.view, elementProps);
		}

		if (currentTab.type === entityTabType.subEntityList) {
			return React.createElement(currentTab.view, {
				...elementProps,
				tableAttributes: currentTab.tableAttributes,
				collectionVMPropName: currentTab.collectionVMPropName,
				entityType: currentTab.entityType,
				// idPropName: currentTab.idPropName,
			});
		}

		if (currentTab.type === entityTabType.files) {
			return React.createElement(currentTab.view, {
				...elementProps,
				entityType: currentTab.parentEntityType,
				// tableAttributes: currentTab.tableAttributes,
				// collectionVMPropName: currentTab.collectionVMPropName,
				// entityType: currentTab.entityType,
				// idPropName: currentTab.idPropName,
			});
		}
	}, [closeFunction, currentTab, isEditing, props.defaultEditingState, props.editMethod, props.id, props.parentId, viewModel]);

	useEffect(() => {
		setVisible(true);

		if (viewModel) {
			if (props.editMethod === EditMethod.create) {
				viewModel.create(props.id);
				return;
			}

			viewModel.read(props.id);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id, props.editMethod]);

	if (visible)
		return createPortal(
			<div
				className={styles.modal}
				onClick={() => closeFunction()}
			>
				<div
					className={styles.popup}
					onClick={(event) => event.stopPropagation()}
				>
					<TabList
						{...tabListProps}
						currentTabIndex={currentTabIndex}
						disabled={isEditing}
					/>

					<div className={styles.entityView}>{getCurrentViewElement()}</div>
					<FontAwesomeIcon
						icon={'circle-xmark'}
						size="2x"
						className={styles.closeButton}
						onClick={() => closeFunction()}
					/>
				</div>
			</div>,
			document.body
		);
	else return null;
}

function TabList(props: ITabListProps) {
	const generateTabProps = useCallback(
		(tab: ITabListTabProps, index: number) => {
			let className = styles.tab;
			let onClick: (() => void) | undefined = undefined;
			if (tab.onClick) {
				className += ` ${styles.clickable}`;
				onClick = tab.onClick;
			} else {
				className += ` ${styles.tabHeader}`;
			}

			if (props.currentTabIndex === index) {
				className += ` ${styles.selected}`;
			}

			if (props.disabled) {
				className += ` ${styles.disabled}`;
				onClick = undefined;
			}

			const tabProps = {
				key: index,
				className: className,
				onClick: onClick,
			};

			return tabProps;
		},
		[props.currentTabIndex, props.disabled]
	);

	return (
		<div className={styles.tabList}>
			{props.tabs.map((tab, index) => (
				<div {...generateTabProps(tab, index)}>
					{tab.icon && (
						<div className={styles.tabIcon}>
							<FontAwesomeIcon
								size="lg"
								icon={tab.icon}
							/>
						</div>
					)}
					{t(tab.title)}
				</div>
			))}
		</div>
	);
}
