import { Dispatch, SetStateAction } from 'react';
import { IFullFieldDefinition } from '../models/_Custom/FullFieldDefinition';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import { FieldValue } from '../models/Model_Fields/FieldValue';
import { IModelProperty, IViewModelFuncCallbacks, ModelFromEntity } from './_viewModel.interfaces';
import { isNilOrEmpty, isNotNilOrEmpty } from '../utilities/utils';

export namespace basicVMFunctions {
	function addFieldValuesToModel<Entity>(model: ModelFromEntity<Entity>, entity: Entity, fieldDefinitions: FieldDefinition[]): ModelFromEntity<Entity> {
		fieldDefinitions.forEach((fieldDefinition) => {
			const entityWithFieldValues = entity as { fieldValues: FieldValue[] };
			let foundFieldValue;
			if (entityWithFieldValues.fieldValues)
				foundFieldValue = entityWithFieldValues.fieldValues.find((fieldValue) => fieldValue.fieldDefinition_Id === fieldDefinition.id);

			const modelProperty: IModelProperty = {
				value: foundFieldValue ? foundFieldValue.value : null,
				isValid: true,
			};
			model[`fieldDefinition_${fieldDefinition.id}`] = modelProperty;
		});

		return model;
	}

	export function createModelFromEntity<Entity>(entity: Entity, fieldDefinitions?: FieldDefinition[]): ModelFromEntity<Entity> {
		const object: Object = {};
		for (const propertyName in entity) {
			object[propertyName as string] = {
				value: entity[propertyName],
				isValid: true,
			};
		}

		let model = object as ModelFromEntity<Entity>;

		if (fieldDefinitions) model = addFieldValuesToModel(model, entity, fieldDefinitions);

		return model;
	}

	function addModelFieldValueToEntity<Entity>(
		model: ModelFromEntity<Entity>,
		entity: Object,
		propertyName: string,
		fieldDefinitions: FieldDefinition[]
	): Object {
		const fieldDefinitionId = propertyName.replace('fieldDefinition_', '');

		const foundFieldDefinition = fieldDefinitions!.find((fieldDefinition) => fieldDefinition.id === fieldDefinitionId);

		if (foundFieldDefinition) {
			const fieldValue = {
				$type: 'fieldValue',
				fieldDefinition_Id: fieldDefinitionId,
				value: model[propertyName].value as string,
			};

			if (!Array.isArray(entity['fieldValues'])) entity['fieldValues'] = [];

			const foundIndex = entity['fieldValues'].findIndex((entityFieldValue) => entityFieldValue.fieldDefinition_Id === fieldDefinitionId);
			if (foundIndex < 0) entity['fieldValues'].push(fieldValue);
			else entity['fieldValues'].splice(foundIndex, 1, fieldValue);
		}

		return entity;
	}

	export function createEntityFromModel<Entity>(model: ModelFromEntity<Entity>, fieldDefinitions?: FieldDefinition[]): Entity {
		let entity = {};
		for (const propertyName in model) {
			if (propertyName.startsWith('fieldDefinition_') && fieldDefinitions) {
				entity = addModelFieldValueToEntity<Entity>(model, entity, propertyName, fieldDefinitions);
			} else {
				entity[propertyName as string] = model[propertyName].value;
			}
		}
		return entity as Entity;
	}

	export function filterFieldDefinitionsByEntityType_Name(fieldDefinitions: IFullFieldDefinition[], entityType_Name: string) {
		const filteredFieldDefinitions = fieldDefinitions.filter((fieldDefinition) => fieldDefinition.entityType_Name === entityType_Name);

		return filteredFieldDefinitions;
	}

	export function validateAllProperties<Entity>(
		model: ModelFromEntity<Entity>,
		setModel: Dispatch<SetStateAction<ModelFromEntity<any>>>,
		validator: (propertyName: any, value: any) => boolean
	) {
		const modelClone = structuredClone(model);
		Object.entries(modelClone).forEach(([propertyName, modelProperty]) => {
			const property = modelProperty as IModelProperty;
			const isValid = validator(propertyName, property.value);

			modelClone[propertyName].isValid = isValid;
		});
		setModel(modelClone);
		return true;
	}

	export function checkIfModelIsValid(model: ModelFromEntity<Object>) {
		let valid = true;

		Object.entries(model).forEach(([name, modelProperty]) => {
			if (!modelProperty.isValid) {
				valid = false;
			}
		});

		return valid;
	}

	export function setModelPropertyValue<Entity>(
		propertyName: keyof Entity,
		value: any,
		model: ModelFromEntity<Entity>,
		setModel: (model: SetStateAction<ModelFromEntity<Entity>>) => void,
		validator: (propertyName: any, value: any) => boolean,
		callback?: () => void
	) {
		const modelCopy = { ...model };
		const modelProperty = modelCopy[propertyName];
		modelProperty.value = value;

		setModel(modelCopy);
		validateAllProperties(model, setModel, validator);

		if (callback) callback();
	}

	export async function readEntity<Entity>(
		apiReadFunc: (id) => Promise<Entity | undefined>,
		entityFieldDefinitions: IFullFieldDefinition[],
		setModel: (value: SetStateAction<ModelFromEntity<Entity>>) => void,
		setUnchangedModel: (value: SetStateAction<ModelFromEntity<Entity>>) => void,
		id?: string,
		refreshCallback?: () => void
	) {
		if (id) {
			const entity = await apiReadFunc(id);

			if (entity) {
				const entityModel = createModelFromEntity<Entity>(entity, entityFieldDefinitions);

				setModel(entityModel);
				// unchanged model needs to be a copy of the model's initial properties, not a copy of its object reference
				setUnchangedModel(structuredClone(entityModel));
			}

			if (refreshCallback) refreshCallback();
		}
	}

	export async function updateEntity<Entity>(
		hasChanges: boolean,
		apiUpdateFunc: (model?: Entity) => Promise<Entity | undefined>,
		model: ModelFromEntity<Entity>,
		entityFieldDefinitions: IFullFieldDefinition[],
		setHasSavedChanges: (value: SetStateAction<boolean>) => void,
		refreshEntity: () => Promise<void>,
		updateCallback?: (entity: Entity) => void
	): Promise<boolean> {
		if (hasChanges) {
			const entity = await apiUpdateFunc(createEntityFromModel(model, entityFieldDefinitions));
			setHasSavedChanges(true);
			await refreshEntity();

			const didUpdate = isNotNilOrEmpty(entity);
			if (updateCallback && didUpdate) updateCallback(entity!);
			return didUpdate;
		} else return false;
	}

	export async function deleteEntity(
		apiDeleteFunc: (id: string) => Promise<boolean>,
		setHasSavedChanges: (value: SetStateAction<boolean>) => void,
		id?: string,
		deleteCallback?: () => void
	): Promise<boolean> {
		if (id) {
			const didDelete = await apiDeleteFunc(id);

			if (didDelete) {
				setHasSavedChanges(true);

				if (deleteCallback) {
					deleteCallback();
				}
			}

			return didDelete;
		} else return false;
	}

	export function appendCallbacks(callbacks: IViewModelFuncCallbacks<any>, additionalCallbacks: IViewModelFuncCallbacks<any>) {
		const newCallbacks: IViewModelFuncCallbacks<any> = {};
		newCallbacks.setModelPropertyValue = () => {
			if (callbacks.setModelPropertyValue) callbacks.setModelPropertyValue();
			if (additionalCallbacks.setModelPropertyValue) additionalCallbacks.setModelPropertyValue();
		};
		newCallbacks.refresh = () => {
			if (callbacks.refresh) callbacks.refresh();
			if (additionalCallbacks.refresh) additionalCallbacks.refresh();
		};
		newCallbacks.update = (entity) => {
			if (callbacks.update) callbacks.update(entity);
			if (additionalCallbacks.update) additionalCallbacks.update(entity);
		};
		newCallbacks.delete = () => {
			if (callbacks.delete) callbacks.delete();
			if (additionalCallbacks.delete) additionalCallbacks.delete();
		};
		return newCallbacks;
	}

	export function createEntityCallback(entity: { id }, id: string, idSetter: (id: string) => void) {
		if (id !== entity.id) {
			idSetter(entity.id);
		}
	}
}
