import { useCallback, useEffect, useRef, useState, Fragment } from 'react';
import styles from './inputFields.module.scss';
import { IDropdownComponentProps, IDropdownMenuProps, IDropdownOptionProps } from './inputField.types';
import { SearchBar } from '../SearchBar/SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { t } from 'i18next';
import { Button } from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
export enum DropDownVariant {
	default = 'default',
	text = 'text',
	range = 'range',
	date = 'date',
	multiCheckboxes = 'multiCheckboxes',
}
export function DropdownComponent(props: IDropdownComponentProps) {
	const [open, setOpen] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const inputClick = useCallback(
		(e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();

			if (!props.readOnly) setOpen(true);
		},
		[props.readOnly]
	);

	const handleClickOutside = useCallback((e: MouseEvent | any): void => {
		if (!ref?.current?.contains(e.target as HTMLDivElement)) {
			setOpen(false);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const findLabel = useCallback(() => {
		if (props.value !== undefined && props.value !== null && props.options?.length !== undefined && props?.options?.length > 0) {
			if (Array.isArray(props.value)) {
				const values = props.value as Array<string>;
				const foundOptions = props.options?.filter((option) => values.some((value) => option.value === value));

				const foundLabels = foundOptions?.map((option) => option.label);

				return foundLabels;
			} else {
				const foundLabel = props.options?.find((option) => option.value.toString() === props.value.toString())?.label;

				if (foundLabel) return foundLabel;
			}
		}

		return '';
	}, [props.options, props.value]);

	const textInputRef = useRef<any>();

	const parseValueArray = useCallback((values: any) => {
		return !Array.isArray(values) ? values : values.join(', ');
	}, []);

	return (
		<div
			className={`${styles.dropdownWrapper} ${props.variant ? styles.filterDropDown : ''} ${props.activeFilter ? styles.activeFilter : ''} ${
				props?.numOfSelected && props?.numOfSelected > 2 ? styles.hasSelected : ''
			} ${props.hidden ? styles.hidden : ''}`}
			onFocus={inputClick}
			onClick={(e) => {
				inputClick(e);
				handleClickOutside(e);
			}}
			ref={ref}
		>
			{props.filterName && (
				<div className={styles.filterName}>
					{props.filterName}
					{props.activeFilter ? ':' : ''}
				</div>
			)}
			{props.readOnly ? (
				<input
					type="text"
					ref={textInputRef}
					className={`${styles.input} ${props.hasError && styles.invalid}`}
					value={props.variant === DropDownVariant.text ? props.value : findLabel()}
					onFocus={inputClick}
					onClick={inputClick}
					readOnly={props.readOnly || open}
				/>
			) : (
				!props.hideSelectedFilters && (
					<div className={`${styles.valuesContainer} ${props?.numOfSelected && props?.numOfSelected > 2 && styles.valuesSelected}`}>
						{props.variant === DropDownVariant.text ? props.value : parseValueArray(findLabel())}
					</div>
				)
			)}
			{!props.readOnly && (
				<Fragment>
					<div
						className={`${styles.arrowSelectedBlock} ${props.hideSelectedFilters && styles.filterNamesHidden}`}
						ref={textInputRef}
					>
						{props.activeFilter && props?.numOfSelected && (
							<div className={styles.numOfSelected}>{props?.numOfSelected > 2 && `[ ${props?.numOfSelected} ]`}</div>
						)}
						<FontAwesomeIcon
							icon={faChevronDown}
							size="sm"
							color={'gray'}
						/>
					</div>
				</Fragment>
			)}
			{open && (
				<DropdownMenu
					{...props}
					closeFunction={() => setOpen(false)}
					variant={props.variant ? props.variant : DropDownVariant.default}
				/>
			)}
		</div>
	);
}

function DropdownMenu(props: IDropdownMenuProps) {
	const valueString = props.value ? props.value.toString() : undefined;
	const allowEmptySelection = !Array.isArray(props.value) && !props.required;

	useEffect(() => {
		document.body.addEventListener('click', props.closeFunction);

		return () => {
			document.body.removeEventListener('click', props.closeFunction);
		};
	}, [props.closeFunction]);

	const options = props.options !== undefined ? [...props.options] : [];
	if (allowEmptySelection && props.variant !== DropDownVariant.multiCheckboxes) options.unshift({ value: undefined, label: t('noOptionSelected') });

	return (
		<div
			className={styles.dropdownBox}
			onClick={(e) => {
				e.stopPropagation();
				e.nativeEvent.stopImmediatePropagation();
			}}
		>
			<div className={styles.topBar}>
				{props.searchCallback !== undefined && (
					<SearchBar
						className={styles.dropdownSearch}
						searchCallback={props.searchCallback}
						border
						autoFocus
						noIcon
						searchPlaceholder={`${t('search')}...`}
					/>
				)}
			</div>
			{getDropdownContentVariants(props, options, valueString)}
		</div>
	);
}

const getDropdownContentVariants = (props, options, valueString) => {
	switch (props.variant) {
		case DropDownVariant.text:
			return (
				<div className={styles.optionList}>
					<div className={styles.textFilter}>
						<input
							onChange={props.onChange}
							defaultValue={props.value}
							placeholder="Type ..."
						/>
						<div className={styles.filterAction}>
							<Button
								i18nKey="update"
								theme="confirm"
								onClick={props.onUpdate}
							/>
						</div>
					</div>
				</div>
			);
		case DropDownVariant.multiCheckboxes:
			return (
				<>
					{props.options && (
						<div className={styles.optionList}>
							{options.map((option, key) => {
								let selected = false;
								if (Array.isArray(props.value))
									selected = props.value.some((value) => {
										return value === option.value;
									});
								else {
									selected = valueString === option.value;
								}
								return (
									<DropdownOption
										key={key}
										label={option.label}
										onClick={() => props.onChange(option)}
										selected={selected}
										hidden={option.hidden}
										variant={props.variant}
									/>
								);
							})}
						</div>
					)}
					{props.hasSelectAllOptions && (
						<div
							className={styles.clearFilters}
							onClick={props.selectAllOptions}
						>
							{t('selectAllOptions')}
						</div>
					)}
					{props.hasClearOptions && (
						<div
							className={styles.clearFilters}
							onClick={props.clearOptions}
						>
							{t('clearOptions')}
						</div>
					)}
				</>
			);
		default:
			return (
				<>
					{props.options && (
						<div className={styles.optionList}>
							{options.map((option, key) => {
								let selected = false;
								if (Array.isArray(props.value))
									selected = props.value.some((value) => {
										return value === option.value;
									});
								else {
									selected = valueString === option.value;
								}
								return (
									<DropdownOption
										key={key}
										label={option.label}
										onClick={() => props.onChange(option.value)}
										selected={selected}
										hidden={option.hidden}
									/>
								);
							})}
						</div>
					)}
				</>
			);
	}
};

function DropdownOption(props: IDropdownOptionProps) {
	if (props.hidden) return null;

	switch (props.variant) {
		case DropDownVariant.multiCheckboxes:
			return (
				<div
					onClick={props.onClick}
					className={styles.checkboxFilter}
				>
					<Checkbox
						defaultChecked={props.selected}
						label={props.label}
						onChange={props.onClick}
					/>
				</div>
			);
		default:
			return (
				<div
					className={`${styles.dropdownOption} ${props.selected && styles.selected}`}
					onClick={props.onClick}
				>
					{props.label}
				</div>
			);
	}
}

export default DropdownComponent;
