import _ from 'lodash';
import EventBus from '../utilities/EventBus';
import { useCallback, useEffect, useState } from 'react';
import { IPropertyValidation } from '../models/Validation_EntityValidation/PropertyValidation';
import { CrudType, EntityType, FieldAttributeType } from '../globals/enums';
import { convertDateToNetIsoString, getObjProps, isDefined, isNilOrEmpty, isNothing } from '../utilities/utils';
import { IApiMapping, IBaseModel, IMappings, IViewModelStatus } from './_viewModel.interfaces';
import { ModelBase } from '../models/AbstractModel/ModelBase';
import { UserManager } from '../utilities/UserManager';
import { validationUtility as V } from '../utilities/validationUtility';
import { tryFire, tryFireAsync } from '../utilities/EventService';
import { IFullFieldDefinition } from '../models/_Custom/FullFieldDefinition';
import { IFieldValue } from '../models/Model_Fields/FieldValue';
import { useAppSelector } from '../store/hooks';
import { ISaveFieldValue } from '../models/_Custom/SaveFieldValue';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import { GuidFactory } from '../utilities/GuidFactory';

export function BaseViewModel<T = ModelBase | undefined>(
	instanceName: string,
	modelEntityType: EntityType,
	apiReader?: Function,
	apiUpdater?: Function,
	apiDeleter?: Function,
	mappings?: IMappings
): IBaseModel<T> {
	//TEMPORARY 'models'
	const [tempModel, setTempModel] = useState<T | undefined>();
	const tempEntityValidations = useAppSelector((s) => s.globals.entityValidation);
	const tempFieldDefinitions = useAppSelector((s) => s.globals.fieldDefinitions);

	//FINAL 'models'
	const [model, setModel] = useState<T>();
	const [propertyValidations, setPropertyValidations] = useState<IPropertyValidation[]>();
	const [fieldDefinitions, setFieldDefinitions] = useState<IFullFieldDefinition[]>();

	//Rest
	const [crudType, setCrudType] = useState<CrudType>();
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [isValid, setIsValid] = useState<boolean>(false);
	const [hasSavedChanges, setHasSavedChanges] = useState<boolean>(false);

	useEffect(process, [tempModel, tempEntityValidations, tempFieldDefinitions]);
	useEffect(() => validate(model, fieldDefinitions, propertyValidations), [model]);

	const userManager = new UserManager();

	function doCreate(modelInstance: T): boolean {
		doReset(true);

		const success = tryFire(updateTempModel, { ...modelInstance });

		if (success) {
			setCrudType(CrudType.Create);
			setHasSavedChanges(true);
		}

		return success;
	}

	async function doRead(id: string): Promise<boolean> {
		doReset(true);
		const success = await tryFireAsync(doReadOrUpdateModel, id);
		return success;
	}

	async function doReadOrUpdateModel(id: string, entity?: any): Promise<boolean> {
		if (apiReader === undefined) return false;

		if (entity === undefined) {
			entity = await apiReader(id);

			if (entity) {
				id = entity['id'];
			}
		}

		let success = isDefined(entity);

		if (success) {
			success = await updateMappings(entity, id);
		}
		if (success) {
			success = updateTempModel(entity);
		}

		return success;
	}

	async function doUpdate(): Promise<boolean> {
		async function pushAndUpdateModel() {
			if (apiUpdater === undefined) return false;

			await processAndUpdateFieldDefinitions();
			const cleanedEntity = await getCleanedModel();
			const entity = await apiUpdater(cleanedEntity);

			if (entity && apiReader) {
				return await doReadOrUpdateModel(GuidFactory.Empty, entity);
			}

			return entity !== undefined; //success
		}

		doReset();

		if (model === undefined) {
			return doReport(CrudType.Update, false);
		}

		if (apiUpdater === undefined) {
			return doReport(CrudType.Update, false, 'BASEVIEWMODEL - Cannot update, no apiUpdater', true);
		}

		if (isValid === false) {
			console.warn(`BASEVIEWMODEL, viewmodel should not be saved, because it is inValid!`);
		}

		let success = await tryFireAsync(pushAndUpdateModel);

		if (success) {
			setCrudType(CrudType.Update);
			setHasSavedChanges(true);
		}

		return success;
	}

	async function doDelete(clearModel: boolean = true): Promise<boolean> {
		doReset();

		if (model === undefined) {
			return doReport(CrudType.Delete, false);
		}

		if (apiDeleter === undefined) {
			return doReport(CrudType.Delete, false, 'BASEVIEWMODEL - Cannot delete, no apiDeleter', true);
		}

		const success = await tryFireAsync(apiDeleter, getId());

		if (success && clearModel && clearModel === true) {
			setModel(undefined);
			setHasSavedChanges(true);
		}

		return doReport(CrudType.Delete, success);
	}

	function doReport(type: CrudType | undefined, success: boolean, message?: string, printMessage: boolean = false) {
		const bus = EventBus.instance();

		const params: IViewModelStatus<T> = {
			crudType: type,
			success: success,
			id: getId(),
			entity: model,
			message: message,
		};

		if (printMessage) {
			console.warn(message);
		}

		bus.dispatch(instanceName, params);

		return success;
	}

	function doReset(clearModel: boolean = false) {
		if (clearModel) setModel(undefined);
		setIsDirty(false);
		setIsValid(false);
	}

	function process() {
		// console.log('process - tempModel null = ' + (tempModel === undefined || tempModel === null));
		// console.log('process - tempFieldDefinitions null = ' + (tempFieldDefinitions === undefined || tempFieldDefinitions === null));
		// console.log('process - tempEntityValidations null = ' + (tempEntityValidations === undefined || tempEntityValidations === null));

		if (tempModel === undefined || tempModel === null) return;
		if (tempFieldDefinitions === undefined || tempFieldDefinitions === null) return;
		if (tempEntityValidations === undefined || tempEntityValidations === null) return;

		const currentFieldDefinitions = V.filterEntityFieldDefinitions(tempFieldDefinitions, getEntityType());
		const currentPropertyValidations = V.filterEntityValidationsToPropertyValidations(tempEntityValidations, getEntityType());
		let currentFieldValues: IFieldValue[] = [];

		//EXTRACT FIELDVALUES FROM (temp)Model
		//TODO: Check if HasProperty?
		if (tempModel['fieldValues']) {
			currentFieldValues = [...tempModel['fieldValues']];
		}

		//UPDATE MODEL
		currentFieldDefinitions.forEach((def) => {
			const fieldDefId = def.id;
			let fieldDefValue = def.defaultValue;
			let fieldValueId = '';

			//Do we have this fieldValue?
			const fieldValue = currentFieldValues.find((x) => x.fieldDefinition_Id === fieldDefId);

			if (fieldValue) {
				fieldDefValue = fieldValue.value;
				fieldValueId = fieldValue['id'];
			}

			//value is now defaultValue or assigned value. Add to 'model'
			tempModel![`f_${fieldDefId}`] = fieldDefValue;

			const propertyValidation = V.convertFieldDefinitionToPropertyValidation(def) as IPropertyValidation;

			if (propertyValidation) {
				currentPropertyValidations.push(propertyValidation);
			}
		});

		//VALIDATE
		validate(tempModel, currentFieldDefinitions, currentPropertyValidations);

		//UPDATE STATE
		setFieldDefinitions(currentFieldDefinitions);
		setPropertyValidations(currentPropertyValidations);
		updateFinalModel(tempModel);

		doReport(crudType, true);
	}

	async function updateMappings(entity: any, id: string): Promise<boolean> {
		if (!mappings) return true;
		if (!mappings.ApiMappings) return true;

		const promises = new Array<Object>(mappings.ApiMappings.length);

		mappings.ApiMappings.forEach((mapping, index) => {
			promises[index] = updateCollection(entity, id, mapping);
		});

		const results = await Promise.all(promises);

		return results.every((x) => x === true);
	}

	async function processAndUpdateFieldDefinitions() {
		if (model === undefined || model === null) return;
		if (fieldDefinitions === undefined || fieldDefinitions.length === 0) return;

		const modelId = getId();
		const modelTypeName = getTypeString(true);
		let arrFieldValues: ISaveFieldValue[] = [];

		if (model['fieldValues'] && Array.isArray(model['fieldValues'])) {
			arrFieldValues = model['fieldValues'] as ISaveFieldValue[];
		}

		//Add new fieldDefinitions to arrFieldValues or update existing ones
		fieldDefinitions.forEach((apiFieldDefinition) => {
			const apiFieldDefinitionId = apiFieldDefinition.id;
			const apiFieldDefinitionType = apiFieldDefinition.$type;
			const apiFieldDefinitionIsDate = apiFieldDefinitionType === FieldAttributeType.fieldDefinitionDateTime;
			let lclFieldDefinition: ISaveFieldValue | undefined = undefined;
			let lclFieldValue = model[`f_${apiFieldDefinitionId}`];

			if (arrFieldValues) {
				lclFieldDefinition = arrFieldValues.find((x) => x.fieldDefinition_Id === apiFieldDefinitionId);
			}
			if (apiFieldDefinitionIsDate && lclFieldValue !== null) {
				lclFieldValue = convertDateToNetIsoString(lclFieldValue);
			}

			if (lclFieldDefinition) {
				//EXISTING => update model
				lclFieldDefinition.value = lclFieldValue;
			} else if (lclFieldValue) {
				//NEW with a value => ADD

				const field: ISaveFieldValue = {
					$type: 'fieldValue',
					fieldDefinition_Id: apiFieldDefinitionId,
					entityType_Name: modelTypeName,
					value: lclFieldValue,
				};

				if (modelId !== null) {
					//this is an existing company
					field.extendedKey_Id = modelId;
				}

				arrFieldValues.push(field);
			}
		});

		model['fieldValues'] = arrFieldValues;
	}

	async function getCleanedModel() {
		if (model === undefined || model === null) return;

		const obj: Record<string, any> = {};

		//Clean
		for (const key in model) {
			const value = model[key];

			if (key === 'constructor') continue;
			if (key.startsWith('f_')) continue;
			if (value === null) continue;
			if (value === undefined) continue;
			if (value === '') continue;
			if (value instanceof Function) continue;
			if (value instanceof Array && key !== 'fieldValues') continue;

			obj[key] = value;
		}

		return obj;
	}

	function validate(entity?: T, fieldDefs?: FieldDefinition[], propVals?: IPropertyValidation[]) {
		let isValid = true;

		//Check conditions
		isValid &&= entity !== undefined && entity !== null;
		isValid &&= propVals !== undefined && propVals !== null;
		isValid &&= fieldDefs !== undefined && fieldDefs !== null;

		if (isValid === false) {
			setIsValid(false);
			return;
		}

		//The actual validation
		isValid = validateInternal(entity!, propVals!);

		setIsValid(isValid);
	}

	function validateInternal(entity: T, propsVals: IPropertyValidation[]): boolean {
		function isInternalPropertyValid(key: string): boolean {
			const value = entity[key];
			const attributes = getPropertyValidationAttributes(key, propsVals);
			let errorMessages: string[] = [];

			if (attributes) {
				errorMessages = V.validate(attributes, value).errorMessageKeys;
			}

			if (errorMessages.length === 0) return true;
			return false;
		}

		let isLegit = true;

		for (let key in model as object) {
			if (key.startsWith('_')) key = key.substring(1);
			isLegit = isInternalPropertyValid(key);
			if (isLegit === false) {
				break;
			}
		}

		return isLegit;
	}

	async function updateCollection(entity: any, id: string, mapping: IApiMapping): Promise<boolean> {
		if (entity === undefined) return false;
		const queryParam = mapping.apiFuncQueryParamName ? mapping.apiFuncQueryParamName : 'company_Id';
		const query = mapping.apiFuncQuery;
		query[queryParam] = id;
		const data = await mapping.apiFunc(query);
		if (data === undefined) return false;
		entity[mapping.collectionName] = data;
		return true;
	}

	function updateTempModel(entity: T) {
		if (entity === undefined) return false;
		setTempModel(entity);
		return true;
	}

	function updateFinalModel(entity: T) {
		if (entity === undefined) return false;
		setModel(entity);
		return true;
	}

	function getProperty(prop: any) {
		if (isNothing(model)) return undefined;

		return model[prop];
	}

	//Was onChange
	const setProperty = useCallback(
		(prop: any, value: any) => {
			if (model === undefined) return;
			if (model === null) return;
			if (!prop) return;

			model[prop] = value;
			const clone = { ...model };

			setModel(clone as T);
			setIsDirty(true);
		},
		[model]
	);

	function isPropertyValid(name: any) {
		return getErrorMessages(name) === undefined;
	}

	function isPropertyReadOnly(name: any): boolean {
		if (model === undefined || model === null) return true;
		if (fieldDefinitions === undefined || fieldDefinitions.length === 0) return false;
		if (propertyValidations === undefined || propertyValidations.length === 0) return false;

		const fieldDefinition = fieldDefinitions.find((x) => x.id === name);
		if (fieldDefinition) return fieldDefinition.isReadOnly;

		return false;
	}

	function getPropertyValidationAttributes(propertyName: string, propsVals: IPropertyValidation[]) {
		const validations = propsVals.find((x) => x.propertyName === propertyName);

		if (validations) {
			return validations.entityPropertyValidationAttributes;
		}

		return undefined;
	}

	function getErrorMessages(key: string): string[] | undefined {
		if (model === undefined) return undefined;
		if (!propertyValidations || propertyValidations.length === 0) return undefined;

		const value = getProperty(key);
		const attributes = getPropertyValidationAttributes(key, propertyValidations);
		let errorMessages: string[] = [];

		if (attributes) {
			errorMessages = V.validate(attributes, value).errorMessageKeys;
		}

		if (errorMessages.length === 0) return undefined;
		return errorMessages;
	}

	//#region HELPER_FUNCTIONS
	function getRepresentativeId() {
		return userManager.getCurrentUserId();
	}

	function getCurrentEntity() {
		if (model) return model;
		return tempModel;
	}

	function getTypeCasedString(): string {
		return getTypeString(true);
	}

	function getTypeString(startCase: boolean = false): string {
		const entity = getCurrentEntity();
		let entityTypeName: string | null = null;

		if (entity) {
			entityTypeName = entity['$type'];

			if (startCase && entityTypeName !== null) {
				entityTypeName = _.startCase(entityTypeName);
			}
		}

		if (isNilOrEmpty(entityTypeName)) {
			return modelEntityType;
		}

		return entityTypeName!.toString();
	}

	function getEntityType() {
		return EntityType[getTypeString()];
	}

	function getId() {
		const entity = getCurrentEntity();
		if (entity) return entity['id'];
	}
	//#endregion HELPER_FUNCTIONS

	function getProperties(): { [P in keyof T]: P } {
		return getObjProps<T>();
	}

	return {
		model,
		fieldDefinitions: fieldDefinitions,
		setModel, //this should normally not be used!
		setTempModel, //this should normally not be used!
		isValid,
		isDirty,
		hasSavedChanges,
		doRead,
		doCreate,
		doUpdate,
		doDelete,
		setProperty,
		getProperty,
		getProperties,
		getPropertyErrors: getErrorMessages,
		getRepresentativeId,
		isPropertyValid,
		isPropertyReadOnly,
		getId,
		getType: getTypeString,
		getTypeCased: getTypeCasedString,
	};
}
