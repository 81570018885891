import { useEffect, useState } from 'react';

import { Annotation } from '../../../../models/Model/Annotation';
import { IViewModel } from '../../../../viewmodels/_viewModel.interfaces';
import { IEntityFormDetailProps } from '../../entityDetailPopup.interfaces';
import { FormDetail } from '../_FormDetail';
import { t } from 'i18next';
import BoundInputRows from '../../../InputFields/BoundInputRows/BoundInputRows';
import { stringJoin } from '../_utility';
import { InfoGroup } from '../../../InfoBlocks';
import { CustomFieldEditor } from '../../CustomField/CustomFieldEditor';
import { useCompanyAnnotationViewModel } from '../../../../viewmodels/CompanyAnnotationViewModel';
import { basicVMFunctions } from '../../../../viewmodels/_BasicVMFunctions';

interface IAnnotationFormDetailProps extends IEntityFormDetailProps {}

export const AnnotationFormDetail = (props: IAnnotationFormDetailProps) => {
	const [id, setId] = useState(props.id);

	const viewModel = useCompanyAnnotationViewModel(id, { update: (entity) => basicVMFunctions.createEntityCallback(entity, id, setId) });

	const [editing, setEditing] = useState(!props.id);

	useEffect(() => {
		setId(props.id);
	}, [props.id]);

	useEffect(() => {
		props.setIsDirty(viewModel.hasChanges);
	}, [props, viewModel.hasChanges]);

	useEffect(() => {
		props.setHasSavedChanges(viewModel.hasSavedChanges);
	}, [props, viewModel.hasSavedChanges]);

	return (
		<FormDetail
			vm={viewModel}
			headerText={new Date(viewModel.model.createdDate.value ?? '').toLocaleString()}
			editing={editing}
			setEditing={setEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName="pen"
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('annotation')}
			>
				<BoundInputRows.TextareaRow
					new
					label={t('text')}
					modelProperty={viewModel.model.text}
					setProperty={viewModel.setModelPropertyValue}
					modelValuePropertyName="text"
				/>
			</InfoGroup>

			<CustomFieldEditor
				vm={viewModel}
				editing={editing}
			/>
		</FormDetail>
	);
};
