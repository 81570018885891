import { Fragment, ReactElement, useCallback, useMemo, useState } from 'react';
import { Popup } from '../../Popup/Popup';
import styles from './appointmentFinishPopup.module.scss';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { WizardTab } from './WizardTab/WizardTab';
import { WizardFooter } from './WizardFooter/WizardFooter';
import { SubEntityListDetail } from '../../EntityListDetail/SubEntityListDetail/SubEntityListDetail';
import { AppointmentTableDefinition, CompanyOpportunityTableDefinition, TaskTableDefinition } from '../../DynamicTable/dynamicTable.index';
import { AnnotationEditorList } from '../../EntityListDetail/AnnotationEditor/AnnotationEditorList';
import { DateTimeType } from '../../InputFields/inputField.types';
import { useAppointmentFinishViewModel } from '../../../viewmodels/Related/AppointmentFinishViewModel';
import { BoundInputRowWrapper } from '../../InputFields/BoundInputRows/BoundInputRowWrapper';
import { DateTimeInput } from '../../InputFields/DateTimeInput';
import { InputAction } from '../../InputFields/InputAction';
import { t } from 'i18next';
import { faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { hasModule, moduleNames } from '../../../utilities/authProvider';
import { useDispatch } from 'react-redux';
import { openAlert } from '../../Dialog/dialogSlice';
import { ProgressBar } from '../../ProgressBar/ProgressBar';
import { Trans } from 'react-i18next';

export interface IAppointmentFinishPopupProps {
	id: string;
	companyId: string;
	closeFunction?: () => void;
}

export function AppointmentFinishPopup(props: IAppointmentFinishPopupProps) {
	const vm = useAppointmentFinishViewModel(props.id, props.companyId);
	const [currentTab, setCurrentTab] = useState(0);

	const annotationRequired = hasModule(moduleNames.finishAppointmentAnnotationRequired);

	const dispatch = useDispatch();

	const generateWizardTabs = useCallback(() => {
		const wizardTabs: { i18nKey: string; isMandatory?: boolean; content: ReactElement; headerI18nKey?: string }[] = [
			{
				i18nKey: 'finishAppointment.newOpportunities',
				content: (
					<SubEntityListDetail
						className={styles.entityListView}
						id={vm.companyVM.getId()}
						vm={vm.companyVM}
						collectionVMPropName="opportunities"
						entityType={EntityType.Opportunity}
						tableAttributes={CompanyOpportunityTableDefinition.tableAttributes}
					/>
				),
				headerI18nKey: 'finishAppointment.newOpportunitiesHeader',
			},
			{
				i18nKey: 'finishAppointment.newAnnotations',
				isMandatory: annotationRequired,
				content: (
					<AnnotationEditorList
						className={styles.annotationListView}
						id={props.companyId}
						cvm={vm.appointmentAnnotationCVM}
						headerText={vm.companyVM.getProperty('name')}
					/>
				),
				headerI18nKey: 'finishAppointment.newAnnotationsHeader',
			},
			{
				i18nKey: 'finishAppointment.newTasks',
				content: (
					<SubEntityListDetail
						className={styles.entityListView}
						id={vm.companyVM.getId()}
						vm={vm.companyVM}
						collectionVMPropName="tasks"
						entityType={EntityType.Task}
						tableAttributes={TaskTableDefinition.tableAttributes}
					/>
				),
				headerI18nKey: 'finishAppointment.newTasksHeader',
			},
			{
				i18nKey: 'finishAppointment.newAppointment',
				content: (
					<Fragment>
						<SubEntityListDetail
							className={vm.hasReturnWeek ? styles.appointmentListView : styles.entityListView}
							id={vm.companyVM.getId()}
							vm={vm.companyVM}
							collectionVMPropName="appointments"
							entityType={EntityType.Appointment}
							tableAttributes={AppointmentTableDefinition.tableAttributes}
							entityDetailFormCrudCallbacks={{ update: vm.refreshReturnWeek, delete: vm.refreshReturnWeek }}
						/>
						{vm.hasReturnWeek && (
							<BoundInputRowWrapper
								label={t('finishAppointment.returnWeekLabel')}
								InputField={
									<DateTimeInput
										value={vm.getProperty('returnWeek')}
										onChange={(value) => vm.setProperty('returnWeek', value)}
										type={DateTimeType.week}
									/>
								}
								InputAction={
									<InputAction
										onClick={() => vm.setProperty('returnWeek', vm.defaultReturnWeek)}
										icon={faRefresh}
									/>
								}
							/>
						)}
					</Fragment>
				),
				headerI18nKey: 'finishAppointment.newAppointmentHeader',
			},
		];

		return wizardTabs;
	}, [annotationRequired, props.companyId, vm]);

	const wizardTabs = useMemo(() => generateWizardTabs(), [generateWizardTabs]);

	const propsCloseFunction = props.closeFunction;
	const closePopup = useCallback(async (): Promise<boolean> => {
		if (propsCloseFunction !== undefined) await propsCloseFunction();
		return true;
	}, [propsCloseFunction]);

	const finish = useCallback(async () => {
		const appointmentAnnotations = await vm.getAppointmentAnnotationViews();

		if (!annotationRequired || (appointmentAnnotations !== undefined && appointmentAnnotations.length > 0)) {
			await vm.finish(props.id);
			closePopup();
		} else {
			dispatch(
				openAlert({
					text: 'finishAppointment.appointmentAnnotationRequired',
				})
			);
		}
	}, [annotationRequired, closePopup, dispatch, props.id, vm]);

	return (
		<Popup onCloseRequest={closePopup}>
			<div className={styles.popupFinishAppointment}>
				<div className={styles.container}>
					<div className={styles.header}>
						{wizardTabs.map((tab, index) => (
							<div className={styles.tab}>
								<WizardTab
									isActive={currentTab === index}
									isDone={currentTab > index}
									isMandatory={tab.isMandatory}
									number={index + 1}
									i18nKey={tab.i18nKey}
								/>
							</div>
						))}
						<div className={styles.tab}>
							<WizardTab
								number={0}
								i18nKey={'finish'}
							/>
						</div>
						<ProgressBar
							className={styles.progressBar}
							percentage={((currentTab + 1) / (wizardTabs.length + 1)) * 100 - 10}
						/>
					</div>
					<div className={styles.headerText}>
						<Trans i18nKey={wizardTabs[currentTab].headerI18nKey} />
					</div>
					<div className={styles.content}>{wizardTabs[currentTab].content}</div>
					<div className={styles.footer}>
						<WizardFooter
							step={currentTab}
							leftButtonClick={() => {
								if (currentTab !== 0) setCurrentTab(currentTab - 1);
								else closePopup();
							}}
							rightButtonClick={async () => {
								if (currentTab !== wizardTabs.length - 1) setCurrentTab(currentTab + 1);
								else {
									finish();
								}
							}}
						/>
					</div>
				</div>
			</div>
		</Popup>
	);
}
