import { useCallback, Dispatch, SetStateAction } from 'react';
import DropdownComponent, { DropDownVariant } from '../InputFields/DropdownInput';
import styles from './optionalFiltersDropdown.module.scss';
import { FilterDropDownOption } from '../FilterDropDown/FilterDropDown';

export interface OptionalFiltersOption {
    value: string | null;
    label: string | null;
}
export interface OptionalFiltersDropdownProps {
    options: OptionalFiltersOption[];
    selectedOptionalFiltersValue: OptionalFiltersOption[] | OptionalFiltersOption | any;
    setSelectedOptionalFiltersValue: Dispatch<SetStateAction<Array<OptionalFiltersOption> | any>>;
    setSelectedValue: Dispatch<SetStateAction<OptionalFiltersOption> | any>;
}

const OptionalFiltersDropdown = ({options, selectedOptionalFiltersValue, setSelectedOptionalFiltersValue, setSelectedValue}: OptionalFiltersDropdownProps) => {
    const onChange = useCallback(
        (e: string | any) => {
            const isSelected = selectedOptionalFiltersValue.some((item: OptionalFiltersOption) => item === e.value);
            const newSelectedOptionalFiltersValues = isSelected
                ? selectedOptionalFiltersValue.filter((item: OptionalFiltersOption) => !(item === e.value))
                : [...selectedOptionalFiltersValue, e.value];
            // Set optional filters state
            setSelectedOptionalFiltersValue([...newSelectedOptionalFiltersValues]);
            // If filter was selected and now its removed , remove values from it from selectedOptions state
            if (isSelected) {
                setSelectedValue((prevValue: FilterDropDownOption[]) =>
                    prevValue ? prevValue.filter((item: FilterDropDownOption) => item.type !== e.value) : prevValue
                );
            }
        },
        [selectedOptionalFiltersValue, setSelectedOptionalFiltersValue, setSelectedValue],
    );

    return (
        <div className={styles.container}>
            <DropdownComponent
                options={options}
                value={selectedOptionalFiltersValue}
                onChange={(e) => onChange(e)}
                variant={DropDownVariant.multiCheckboxes}
                filterName="More"
                hideSelectedFilters={true}
            />
        </div>
    )
};

export default OptionalFiltersDropdown;