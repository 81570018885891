import { UnauthorizedFetchRequest } from './apiBase';

const baseUrl = 'api/Oidc';
const providerUrl = `${baseUrl}/common/provider`;

export function getProviders(): Promise<Response> {
	const url = { base: providerUrl };

	return UnauthorizedFetchRequest(url, 'GET');
}

export enum ProviderNames {
	'MS-COMMON' = 'MS-COMMON',
}

export async function getProvider(providerName: ProviderNames): Promise<any> {
	const res = await getProviders();
	const providers = await res.json();

	if (providers) {
		const msProvider = providers.find((provider) => provider.name === providerName);

		return msProvider;
	}
}

export function getProviderLoginUrl(providerId: string): Promise<Response> {
	const url = { base: baseUrl, path: `/provider/${providerId}/loginurl` };

	return UnauthorizedFetchRequest(url, 'POST');
}

export async function getMicrosoftLoginUrl(msProvider: any): Promise<string> {
	const url = await (await getProviderLoginUrl(msProvider.id)).json();

	return url;
}

export async function oidcAuthenticate(code: string, state: string) {
	const url = { base: baseUrl, path: `/authenticate?code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}` };

	return UnauthorizedFetchRequest(url, 'POST')
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error(err);
			return err;
		});
}

export async function oidcGetRepresentative(linkToken: string) {
	const url = { base: baseUrl, path: `/representative?linkToken=${encodeURIComponent(linkToken)}` };

	return UnauthorizedFetchRequest(url, 'GET');
}

export async function oidcGetRegisterUrl(linkToken: string) {
	const url = { base: baseUrl, path: `/registerurl?linkToken=${encodeURIComponent(linkToken)}` };

	return UnauthorizedFetchRequest(url, 'GET');
}
