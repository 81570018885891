import styles from './authentication.module.scss';
import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LoginLayout } from '../../components/LoginPanel/LoginLayout';

import { LoginUser } from '../../api/UserAPI';
import { ILoginInput } from '../../globals/types';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Button } from '../../components';
import { clearTokenCookie, setTokenCookie } from '../../utilities/authProvider';
import { updateGlobals } from '../../store/store';
import { closeDialog, openAlert } from '../../components/Dialog/dialogSlice';
import { SSOLoginButton } from '../../components/ButtonSSOLogin/SSOLoginButton';
import { useDispatch } from 'react-redux';
import { oidcAuthenticate, ProviderNames } from '../../api/OidcApi';

export function LoginPage() {
	const passwordInputRef = useRef<HTMLInputElement>(null);
	const [usernameInput, setUserNameInput] = useState('');
	const [passwordInput, setPasswordInput] = useState('');
	const [loading, setLoading] = useState(false);
	const [loggingInWithMicrosofSSO, setLoggingInWithMicrosofSSO] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const changeHandler = useCallback((event: FormEvent<EventTarget>, valueSetter) => {
		const target = event.target as HTMLInputElement;

		valueSetter(target.value);
	}, []);

	const handleDialogClose = useCallback(() => {
		passwordInputRef.current!.focus();
	}, []);

	const handleSubmit = useCallback(
		(e: FormEvent) => {
			e.preventDefault();
			setLoading(true);
			if (usernameInput && passwordInput)
				LoginUser({
					Username: usernameInput,
					Password: passwordInput,
				})
					.then((res) => {
						switch (res.status) {
							case 200:
								res.json().then((json) => {
									setLoading(false);
									clearTokenCookie();
									setTokenCookie(json);
									updateGlobals();

									navigate('/');
								});
								break;
							case 401:
								dispatch(
									openAlert({
										text: 'verificationFailContent',
										dismissCallBack: handleDialogClose,
									})
								);

								setLoading(false);
								break;
							default:
								dispatch(
									openAlert({
										text: 'anErrorOccurred',
										dismissCallBack: handleDialogClose,
									})
								);

								setLoading(false);
								break;
						}
					})
					.catch((err) => {
						console.error(err);
					});
			else {
				dispatch(
					openAlert({
						text: 'noInputContent',
						dismissCallBack: handleDialogClose,
					})
				);

				setLoading(false);
			}
		},
		[dispatch, handleDialogClose, navigate, passwordInput, usernameInput]
	);

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);

		const hasCodeAndState = searchParams.has('code') && searchParams.has('state');
		const [code, state] = [searchParams.get('code'), searchParams.get('state')];
		const codeAndStateNotEmpty = (code !== '' || code !== null) && (state !== '' || state !== null);

		if (hasCodeAndState) {
			if (codeAndStateNotEmpty) {
				setLoggingInWithMicrosofSSO(true);

				dispatch(
					openAlert({
						title: 'loggingInWithMicrosoftSSO',
						dismissCallBack: handleDialogClose,
						dismissButtonPosition: 'none',
					})
				);

				oidcAuthenticate(code!, state!).then((res) => {
					if (res.status === 200) {
						res.json().then((json) => {
							dispatch(closeDialog());

							setTokenCookie(json, ProviderNames['MS-COMMON']);
							updateGlobals();

							navigate('/');
						});
					} else {
						dispatch(closeDialog());

						dispatch(
							openAlert({
								text: 'anErrorOccurred',
								dismissCallBack: handleDialogClose,
							})
						);

						setLoggingInWithMicrosofSSO(false);
						setLoading(false);
					}
				});
			}
		}
	}, [dispatch, handleDialogClose, navigate]);

	const inputs: ILoginInput[] = [
		{
			stateName: 'usernameInput',
			value: usernameInput,
			valueSetter: setUserNameInput,
			translationName: 'enterUsername',
			autoComplete: 'username',
		},
		{
			stateName: 'passwordInput',
			value: passwordInput,
			valueSetter: setPasswordInput,
			translationName: 'enterPassword',
			type: 'password',
			autoComplete: 'current-password',
			ref: passwordInputRef,
		},
	];

	return (
		<LoginLayout>
			<form onSubmit={handleSubmit}>
				{inputs.map((input, key) => {
					return (
						<p key={key}>
							<input
								name={input.stateName}
								className={styles.inputField}
								type={input.type ? input.type : 'text'}
								value={input.value}
								onChange={(e) => changeHandler(e, input.valueSetter)}
								placeholder={t(input.translationName) as string}
								ref={input.ref}
								autoComplete={input.autoComplete}
								required
								disabled={loggingInWithMicrosofSSO}
							/>
						</p>
					);
				})}
				<p>
					<Button
						i18nKey="login"
						theme="confirm"
						onClick={handleSubmit}
						loading={loading}
						disabled={loggingInWithMicrosofSSO}
					/>
				</p>
				{!loggingInWithMicrosofSSO && (
					<p>
						<SSOLoginButton />
					</p>
				)}
			</form>
			<Link
				className={styles.passwordResetLink}
				to={`/Authentication/LoginReset`}
			>
				<Trans i18nKey="forgotPassword" />
			</Link>
		</LoginLayout>
	);
}
