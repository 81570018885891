import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import styles from './inputFields.module.scss';
import { InputAction } from './InputAction';
import { IHyperlinkInputProps, LinkType } from './inputField.types';

export function HyperlinkInputComponent(props: IHyperlinkInputProps) {
	const getHref = useCallback(() => {
		switch (props.type) {
			case LinkType.email:
				return `mailto:${props.value}`;

			case LinkType.tel:
				return `${props.type}:${props.value}`;

			case LinkType.url:
				const httpRegex = /^https?:\/\//g;
				if (httpRegex.test(props.value)) {
					return props.value;
				} else {
					return `http://${props.value}`;
				}
		}
	}, [props.type, props.value]);

	if (props.readOnly)
		return (
			<div className={styles.hyperlinkWrapper}>
				<a
					href={getHref()}
					target="_blank"
					rel="noreferrer"
				>
					{props.value}
				</a>
			</div>
		);
	else
		return (
			<input
				className={`${styles.input} ${props.hasError && styles.invalid}`}
				value={props.value}
				type={props.type}
				required={props.required}
				onChange={(e) => props.onChange(e.target.value)}
			/>
		);
}
