import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import useEntityInputValidator from '../hooks/validation/entityInputValidator';
import { Opportunity } from '../models/Model/Opportunity';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import { useAppSelector } from '../store/hooks';
import authProvider from '../utilities/authProvider';
import { UserManager } from '../utilities/UserManager';
import { basicVMFunctions } from './_BasicVMFunctions';
import { INewViewModel, IViewModelFuncCallbacks, IViewModelFuncOverrides, ModelFromEntity } from './_viewModel.interfaces';
import { isNilOrEmpty } from '../utilities/utils';

export interface IOpportunityViewModel extends INewViewModel<Opportunity> {}

type OpportunityModel = ModelFromEntity<Opportunity>;

const apiFuncs = {
	read: api.opportunity.getByIdAsync,
	update: api.opportunity.updateAsync,
	delete: api.opportunity.deleteByIdAsync,
};

const userManager = new UserManager();

function generateModelInstance(companyId?: string, representativeId?: string, fieldDefinitions?: FieldDefinition[]): OpportunityModel {
	const opportunity = new Opportunity();
	const model = basicVMFunctions.createModelFromEntity<Opportunity>(opportunity, fieldDefinitions);

	model.createdBy_Id.value = authProvider.getUserId() ?? null;
	model.representative_Id.value = userManager.getCurrentUserId() ?? null;
	model.estimatedSuccessRate.value = 50;

	if (representativeId) {
		model.createdBy_Id.value = representativeId;
		model.representative_Id.value = representativeId;
	}

	if (companyId) {
		model.company_Id.value = companyId;
	}

	return model;
}

export default function useOpportunityViewModel(
	id?: string,
	companyId?: string,
	funcCallbacks?: IViewModelFuncCallbacks<Opportunity>,
	funcOverrides?: IViewModelFuncOverrides<Opportunity>
): IOpportunityViewModel {
	const validator = useEntityInputValidator(EntityType.opportunity);

	const allFieldDefinitions = useAppSelector((state) => state.globals.fieldDefinitions ?? []);
	const fieldDefinitions = useMemo(() => basicVMFunctions.filterFieldDefinitionsByEntityType_Name(allFieldDefinitions, 'Opportunity'), [allFieldDefinitions]);

	const [model, setModel] = useState<OpportunityModel>(generateModelInstance(companyId, undefined, fieldDefinitions));
	const [unchangedModel, setUnchangedModel] = useState<OpportunityModel>(generateModelInstance(companyId, undefined, fieldDefinitions));

	const isValid = useMemo(() => basicVMFunctions.checkIfModelIsValid(model), [model]);

	const hasChanges = useMemo(() => JSON.stringify(model) !== JSON.stringify(unchangedModel) || isNilOrEmpty(model.id.value), [model, unchangedModel]);
	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const setModelPropertyValue = useCallback(
		(propertyName: keyof Opportunity) => (value) =>
			basicVMFunctions.setModelPropertyValue<Opportunity>(propertyName, value, model, setModel, validator, funcCallbacks?.setModelPropertyValue),
		[funcCallbacks?.setModelPropertyValue, model, validator]
	);

	const refresh = useCallback(
		async () => await basicVMFunctions.readEntity<Opportunity>(apiFuncs.read, fieldDefinitions, setModel, setUnchangedModel, id, funcCallbacks?.refresh),
		[fieldDefinitions, funcCallbacks?.refresh, id]
	);

	const update = useCallback(
		async () =>
			await basicVMFunctions.updateEntity<Opportunity>(
				hasChanges,
				apiFuncs.update,
				model,
				fieldDefinitions,
				setHasSavedChanges,
				refresh,
				funcCallbacks?.update
			),
		[fieldDefinitions, funcCallbacks?.update, hasChanges, model, refresh]
	);

	const deleteOpportunity = useCallback(
		async () => await basicVMFunctions.deleteEntity(apiFuncs.delete, setHasSavedChanges, id, funcCallbacks?.delete),
		[funcCallbacks?.delete, id]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	// useEffect(() => {
	// 	basicVMFunctions.validateAllProperties(model, setModel, validator);
	// }, [model, validator]);

	return {
		new: true,
		model,
		fieldDefinitions: fieldDefinitions,
		isValid,
		hasChanges,
		hasSavedChanges,
		refresh: funcOverrides?.refresh ? funcOverrides.refresh : refresh,
		update: funcOverrides?.update ? () => funcOverrides.update!(model, hasChanges, fieldDefinitions) : update,
		delete: funcOverrides?.delete ? funcOverrides.delete : deleteOpportunity,
		setModelPropertyValue: funcOverrides?.setModelPropertyValue ? funcOverrides.setModelPropertyValue(model, setModel, validator) : setModelPropertyValue,
	};
}
