import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { Appointment } from '../../../models/Model/Appointment';
import { IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { updateCompanies } from '../../../store/reducers/CompanySlice';
import { DateTimeType } from '../../InputFields/inputField.types';
import { FormDetail } from './_FormDetail';
import { InfoGroup } from '../../InfoBlocks';
import { EntityType as GlobalEntityType } from '../../../globals/enums';
import { useAppointmentViewModel } from '../../../viewmodels/AppointmentViewModel';
import { DropdownEntityType } from '../../InputFields/BoundDropDown/types';
import { CustomFieldEditor } from '../CustomField/CustomFieldEditor';
import { hasModule, moduleNames } from '../../../utilities/authProvider';
import { FormHeaderAppointment } from './Headers/FormHeaderAppointment';
import { AppointmentFinishPopup } from '../../popups/AppointmentFinishPopup/AppointmentFinishPopup';
import { getObjProps } from '../../../utilities/utils';
import { basicVMFunctions } from '../../../viewmodels/_BasicVMFunctions';

export interface IAppointFormDetailProps extends IEntityFormDetailProps {
	isAgenda: boolean;
}

const propertyNames = getObjProps<Appointment>();

export const AppointmentFormDetail = (props: IAppointFormDetailProps) => {
	const [id, setId] = useState(props.id);

	const [isAppointment, setIsAppointment] = useState(false);
	const [appointmentFinishId, setAppointmentFinishId] = useState<string>();

	const crudCallbacks = useMemo(() => {
		const additionalCallbacks = {
			update: (entity) => {
				basicVMFunctions.createEntityCallback(entity, id, setId);
			},
		};
		if (props.crudCallbacks) return basicVMFunctions.appendCallbacks(props.crudCallbacks, additionalCallbacks);
		else return additionalCallbacks;
	}, [id, props.crudCallbacks]);

	const viewModel = useAppointmentViewModel(id, props.parentId, crudCallbacks);

	useEffect(() => {
		setId(props.id);
	}, [props.id]);

	useEffect(() => {
		props.setIsDirty(viewModel.hasChanges);
	}, [props, viewModel.hasChanges]);

	useEffect(() => {
		props.setHasSavedChanges(viewModel.hasSavedChanges);
	}, [props, viewModel.hasSavedChanges]);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	const titleDetails = useMemo(() => {
		if (isAppointment === false) return 'activityDetails';
		return 'appointmentDetails';
	}, [isAppointment]);

	const hasAppointmentDescriptionModule = hasModule(moduleNames.appointmentDescription);

	const isCompanyDropdownRowReadOnly = useMemo(() => {
		//From company (popup)
		if (props.isAgenda === false) return props.id !== '';

		//Direct Agenda (popup)
		if (props.isEditing) return false;
		return true;
	}, [props.id, props.isAgenda, props.isEditing]);

	const appointmentFinishClickHandler = useCallback(() => {
		setAppointmentFinishId(props.id);
	}, [props.id]);

	const appointmentFinishCloseHandler = useCallback(() => {
		setAppointmentFinishId(undefined);

		viewModel.refresh();
	}, [viewModel]);
	const setIsEditing = props.setIsEditing;

	useEffect(() => {
		if (viewModel.model.$type.value === GlobalEntityType.activity.toString()) {
			setIsAppointment(false);
			setIsEditing(true);
			return;
		}

		setIsAppointment(true);
	}, [setIsEditing, viewModel.model.$type.value]);

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<Fragment>
			<FormDetail
				vm={viewModel}
				headerText={viewModel.model.subject.value}
				editing={props.isEditing}
				setEditing={props.setIsEditing}
				closeDetailPopup={props.closeDetailPopup}
				iconName="calendar-days"
				header={
					isAppointment ? (
						<FormHeaderAppointment
							vm={viewModel}
							editing={props.isEditing}
							setEditing={props.setIsEditing}
							closeDetailPopup={props.closeDetailPopup}
							appointmentFinishClickHandler={appointmentFinishClickHandler}
							editMethod={props.editMethod}
						/>
					) : undefined
				}
			>
				<InfoGroup
					padding
					open={true}
					visible={true}
					title={t('connection')}
				>
					<BoundInputRows.PresetDropDownRow
						new
						label={t('company')}
						modelProperty={viewModel.model.company_Id}
						setProperty={viewModel.setModelPropertyValue(propertyNames.company_Id)}
						DDEntityType={DropdownEntityType.companies}
						readonly={isCompanyDropdownRowReadOnly}
					/>
					<BoundInputRows.PresetDropDownRow
						new
						label={t('appointmentAttributes.type')}
						modelProperty={viewModel.model.appointmentType_Id}
						setProperty={viewModel.setModelPropertyValue(propertyNames.appointmentType_Id)}
						DDEntityType={DropdownEntityType.appointmentTypes}
						readonly={!props.isEditing}
					/>
					<BoundInputRows.PresetDropDownRow
						new
						label={t('appointmentAttributes.contactWith')}
						modelProperty={viewModel.model.contactWith_Id}
						setProperty={viewModel.setModelPropertyValue(propertyNames.contactWith_Id)}
						DDEntityType={DropdownEntityType.contact}
						readId={viewModel.model.company_Id.value}
						readonly={!props.isEditing}
					/>
				</InfoGroup>

				<InfoGroup
					padding
					open={true}
					visible={true}
					title={t(titleDetails)}
				>
					{/* todo: DATE, STARTTIME & ENDTIME structure instead of startdatetime & enddatetime */}
					<BoundInputRows.DateTimeRow
						new
						label={t('appointmentAttributes.startDateTime')}
						modelProperty={viewModel.model.startDateTime}
						setProperty={viewModel.setModelPropertyValue(propertyNames.startDateTime)}
						type={DateTimeType['datetime-local']}
						readonly={!props.isEditing}
					/>
					<BoundInputRows.DateTimeRow
						new
						label={t('appointmentAttributes.endDateTime')}
						modelProperty={viewModel.model.endDateTime}
						setProperty={viewModel.setModelPropertyValue(propertyNames.endDateTime)}
						type={DateTimeType['datetime-local']}
						readonly={!props.isEditing}
					/>

					<BoundInputRows.BooleanRow
						new
						label={t('appointmentAttributes.isClosed')}
						modelProperty={viewModel.model.isClosed}
						setProperty={viewModel.setModelPropertyValue(propertyNames.isClosed)}
						readonly
						booleanLabels={[t('yes'), t('no')]}
						noDeleteButton
					/>

					<BoundInputRows.BooleanRow
						new
						label={t('appointmentAttributes.isFollowUp')}
						modelProperty={viewModel.model.isFollowUp}
						setProperty={viewModel.setModelPropertyValue(propertyNames.isFollowUp)}
						readonly={!props.isEditing}
						booleanLabels={[t('yes'), t('no')]}
						noDeleteButton
					/>
					<BoundInputRows.TextRow
						new
						label={t('appointmentAttributes.location')}
						modelProperty={viewModel.model.location}
						setProperty={viewModel.setModelPropertyValue(propertyNames.location)}
						readonly={!props.isEditing}
					/>
					<BoundInputRows.TextRow
						new
						label={t('appointmentAttributes.subject')}
						modelProperty={viewModel.model.subject}
						setProperty={viewModel.setModelPropertyValue(propertyNames.subject)}
						readonly={!props.isEditing}
					/>
					{hasAppointmentDescriptionModule && (
						<BoundInputRows.TextareaRow
							new
							label={t('appointmentAttributes.description')}
							modelProperty={viewModel.model.description}
							setProperty={viewModel.setModelPropertyValue(propertyNames.description)}
							readonly={!props.isEditing}
						/>
					)}
				</InfoGroup>

				<CustomFieldEditor
					vm={viewModel}
					editing={props.isEditing}
				/>
			</FormDetail>
			{appointmentFinishId !== undefined && viewModel.model.company_Id.value !== null ? (
				<AppointmentFinishPopup
					id={appointmentFinishId}
					companyId={viewModel.model.company_Id.value}
					closeFunction={appointmentFinishCloseHandler}
				/>
			) : null}
		</Fragment>
	);
};
