import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IEntityFormHeaderProps } from '../../entityDetailPopup.interfaces';
import { ButtonsGeneral } from './Buttons/_ButtonsGeneral';
import styles from '../formDetail.module.scss';
import { FormHeaderBase } from './_FormHeaderBase';
import { faSquare, faSquareCheck } from '@fortawesome/pro-light-svg-icons';
import { t } from 'i18next';
import { useMemo } from 'react';
import { INewViewModel } from '../../../../viewmodels/_viewModel.interfaces';
import { Task } from '../../../../models/Model/Task';

export function FormHeaderTask(props: IEntityFormHeaderProps) {
	const completeTaskIcon = useMemo(() => {
		if (props.vm.new) {
			const newVM = props.vm as INewViewModel<Task>;
			return newVM.model.isCompleted.value ? faSquareCheck : faSquare;
		} else return props.vm.getProperty('isCompleted') ? faSquareCheck : faSquare;
	}, [props.vm]);

	const headerText: string = useMemo(() => {
		if (props.vm.new) {
			const newVM = props.vm as INewViewModel<Task>;
			return newVM.model.subject.value;
		} else return props.vm.getProperty('subject');
	}, [props.vm]);

	return (
		<FormHeaderBase
			{...props}
			iconName="list-check"
			headerText={headerText}
		>
			<ButtonsGeneral
				vm={props.vm}
				editing={props.editing}
				setEditing={props.setEditing}
				closeDetailPopup={props.closeDetailPopup}
			>
				<FontAwesomeIcon
					className={`${styles.formButton} ${styles.withDivider}`}
					icon={completeTaskIcon}
					color={!props.vm.isValid ? 'gray' : ''}
					onClick={props.vm['toggleTaskCompleteCommand']}
					size="xl"
					title={t('completeTask')!}
				/>
			</ButtonsGeneral>
		</FormHeaderBase>
	);
}
