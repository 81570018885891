import { t } from 'i18next';
import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { Fragment, useState } from 'react';
import { EditMethod, IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { FormDetail } from './_FormDetail';
import { stringJoin } from './_utility';
import { LinkType } from '../../InputFields_OLD/HyperlinkTextFields';
import { CustomFieldEditor } from '../CustomField/CustomFieldEditor';
import { ICompanyRelatedModel } from '../../../viewmodels/Related/CompanyRelatedViewModel';
import { CompanyTypeName } from '../../../model/_Enums';
import { FormHeaderCompany } from './Headers/FormHeaderCompany';
import { InfoGroup } from '../../InfoBlocks';
import { DropdownEntityType } from '../../InputFields/BoundDropDown/types';
import { hasModule, moduleNames } from '../../../utilities/authProvider';
import { EntityDetailPopup } from '../EntityDetailPopup';
import { QuickAnnotationType } from '../entityDetailPopup.tabConfigurations';

interface ICompanyFormDetailProps extends IEntityFormDetailProps {
	vm: ICompanyRelatedModel;
}

export function CompanyFormDetail(props: ICompanyFormDetailProps) {
	const [quickAnnotationFormVisible, setQuickAnnotationFormVisible] = useState(false);
	const hasCompanyGroupModule = hasModule(moduleNames.companyGroup);
	const hideNewAccount = hasModule(moduleNames.hideNewAccountAndPromoteLead);
	const hasLead = hasModule(moduleNames.lead);

	//TODO: Baseform?
	const p = props.vm.getProperties();
	const e = (p: string) => stringJoin(props.vm.getPropertyErrors(p));

	const isAccount = props.vm.model?.$type === CompanyTypeName.Account;

	return (
		<Fragment>
			<FormDetail
				vm={props.vm}
				headerText={p.name}
				editing={props.isEditing}
				setEditing={props.setIsEditing}
				closeDetailPopup={props.closeDetailPopup}
				iconName="buildings"
				header={
					<FormHeaderCompany
						vm={props.vm}
						editing={props.isEditing}
						setEditing={props.setIsEditing}
						closeDetailPopup={props.closeDetailPopup}
						quickAnnotationClickHandler={() => setQuickAnnotationFormVisible(true)}
						editMethod={props.editMethod}
						id={props.id}
					/>
				}
			>
				<InfoGroup
					padding
					open={true}
					visible={true}
					title={t('companyData')}
				>
					<BoundInputRows.TextRow
						label={t('name')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.name}
						errorMessage={e(p.name)}
						readonly={!props.isEditing}
					/>
					<BoundInputRows.TextareaRow
						label={t('description')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.description}
						errorMessage={e(p.description)}
						readonly={!props.isEditing}
					/>
					<BoundInputRows.PresetDropDownRow
						label={t('companyAttributes.type')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.$type}
						errorMessage={e(p.$type)}
						DDEntityType={DropdownEntityType.companyType}
						readonly={!props.isEditing || props.vm.getProperty('id') || !hasLead || hideNewAccount}
					/>

					{isAccount && (
						<BoundInputRows.TextRow
							label={t('companyAttributes.number')}
							vm={props.vm}
							model={props.vm.model}
							modelValuePropertyName={p.number}
							errorMessage={e(p.number)}
							readonly={!props.isEditing}
						/>
					)}
					{hasCompanyGroupModule && (
						<BoundInputRows.PresetDropDownRow
							label={t('companyAttributes.group')}
							vm={props.vm}
							model={props.vm.model}
							modelValuePropertyName={p.companyGroup_Id}
							errorMessage={e(p.companyGroup_Id)}
							DDEntityType={DropdownEntityType.companyGroup}
							readonly={!props.isEditing}
						/>
					)}
				</InfoGroup>

				<InfoGroup
					padding
					open={true}
					visible={true}
					title={t('primaryRepresentative')}
				>
					<BoundInputRows.PresetDropDownRow
						label={t('primaryRepresentative')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.primaryRepresentative_Id}
						errorMessage={e(p.primaryRepresentative_Id)}
						DDEntityType={DropdownEntityType.activeRepresentatives}
						readonly={!props.isEditing}
					/>
				</InfoGroup>

				{isAccount && (
					<InfoGroup
						padding
						open={true}
						visible={true}
						title={t('companyAttributes.visitingFrequency')}
					>
						<BoundInputRows.PresetDropDownRow
							label={t('companyAttributes.visitingFrequency')}
							vm={props.vm}
							model={props.vm.model}
							modelValuePropertyName={p.visitingFrequency_Id}
							errorMessage={e(p.visitingFrequency_Id)}
							DDEntityType={DropdownEntityType.visitingFrequencies}
							readonly={!props.isEditing}
						/>
					</InfoGroup>
				)}

				<InfoGroup
					padding
					open={true}
					visible={true}
					title={t('contactInfo')}
				>
					<BoundInputRows.HyperlinkRow
						label={t('phoneNumber')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.phoneNumber}
						type={LinkType.tel}
						readonly={!props.isEditing}
						errorMessage={e(p.phoneNumber)}
					/>
					<BoundInputRows.HyperlinkRow
						label={t('companyAttributes.website')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.website}
						type={LinkType.url}
						readonly={!props.isEditing}
						errorMessage={e(p.website)}
					/>
					<BoundInputRows.HyperlinkRow
						label={t('emailAddress')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.emailAddress}
						type={LinkType.email}
						readonly={!props.isEditing}
						errorMessage={e(p.emailAddress)}
					/>
					<BoundInputRows.PresetDropDownRow
						label={t('companyAttributes.primaryVisitingAddress')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.primaryVisitingAddress_Id}
						DDEntityType={DropdownEntityType.address}
						readId={props.vm.getId()}
						readonly={!props.isEditing}
						errorMessage={e(p.primaryVisitingAddress_Id)}
					/>
					<BoundInputRows.PresetDropDownRow
						label={t('companyAttributes.primaryShippingAddress')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.primaryShippingAddress_Id}
						DDEntityType={DropdownEntityType.address}
						readId={props.vm.getId()}
						readonly={!props.isEditing}
						errorMessage={e(p.primaryShippingAddress_Id)}
					/>
					<BoundInputRows.PresetDropDownRow
						label={t('companyAttributes.primaryContact')}
						vm={props.vm}
						model={props.vm.model}
						modelValuePropertyName={p.primaryContact_Id}
						DDEntityType={DropdownEntityType.contact}
						readId={props.vm.getId()}
						readonly={!props.isEditing}
						errorMessage={e(p.primaryContact_Id)}
					/>
				</InfoGroup>

				<CustomFieldEditor
					vm={props.vm}
					editing={props.isEditing}
				/>
			</FormDetail>
			{quickAnnotationFormVisible && (
				<EntityDetailPopup
					new
					id={props.id}
					editMethod={EditMethod.create}
					popupEntityType={QuickAnnotationType.QuickAnnotation}
					closeFunction={() => setQuickAnnotationFormVisible(false)}
				/>
			)}
		</Fragment>
	);
}
