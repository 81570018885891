import React, { FunctionComponent, ReactNode } from 'react';
import { EntityType } from './entityDetailPopup.enums';
import { ISubEntityListDetailProps } from '../EntityListDetail/entityListView.interfaces';
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core';
import { ITableAttribute } from '../DynamicTable/DynamicTable.types';
import { QuickAnnotationType } from './entityDetailPopup.tabConfigurations';
import { INewViewModel, IViewModel, IViewModelFuncCallbacks } from '../../viewmodels/_viewModel.interfaces';

//5118TODO: remove NewEntityDetailPopupConfiguredProps
export interface INewEntityDetailPopupConfiguredProps {
	new: true;
	id: string;
	parentId?: string;
	editMethod: EditMethod;
	popupEntityType: EntityType | QuickAnnotationType;
	closeFunction: (changed?: boolean) => void;
	defaultEditingState?: boolean;
	crudCallbacks?: IViewModelFuncCallbacks<any>;
}
export interface IEntityDetailPopupConfiguredProps {
	new?: boolean;
	id: string;
	parentId?: string;
	editMethod: EditMethod;
	viewModelInstanceName: string;
	popupEntityType: EntityType | QuickAnnotationType;
	closeFunction: (changed?: boolean) => void;
	defaultEditingState?: boolean;
	crudCallbacks?: IViewModelFuncCallbacks<any>;
}

//5118TODO: remove NewEntityDetailPopupProps
export interface INewEntityDetailPopupProps {
	new: true;
	id: string;
	parentId?: string;
	editMethod: EditMethod;
	popupEntityType: EntityType | QuickAnnotationType;
	closeFunction: (changed?: boolean) => void;
	defaultEditingState?: boolean;
	crudCallbacks?: IViewModelFuncCallbacks<any>;
}

export interface IEntityDetailPopupProps {
	new?: boolean;
	id: string;
	parentId?: string;
	editMethod: EditMethod;
	viewModel: IViewModel<any>;
	popupEntityType: EntityType | QuickAnnotationType;
	closeFunction: (changed?: boolean) => void;
	defaultEditingState?: boolean;
	crudCallbacks?: IViewModelFuncCallbacks<any>;
}

export enum EditMethod {
	create = 0,
	update = 1,
}

//tabs
export interface ITabListProps {
	tabs: ITabListTabProps[];
	currentTabIndex?: number;
	disabled?: boolean;
}

export interface ITabListTabProps {
	title: string;
	icon?: IconProp;
	onClick?: () => void;
	currentlySelected?: boolean;
}

export enum entityTabType {
	header,
	form,
	subEntityList,
	files,
}

export type IEntityTab = IEntityHeaderTab | IEntityFormTab | ISubEntityListTab | IEntityFilesTab;

export interface IEntityHeaderTab {
	type: entityTabType.header;
	title: string;
	icon?: IconProp;
}

export interface IEntityFormTab {
	type: entityTabType.form;
	title: string;
	icon?: IconProp;
	view: FunctionComponent<IEntityFormDetailProps>;
}

export interface ISubEntityListTab {
	type: entityTabType.subEntityList;
	tableAttributes: ITableAttribute[];
	title: string;
	icon?: IconProp;
	view: FunctionComponent<ISubEntityListDetailProps>;
	collectionVMPropName: string;
	entityType: EntityType;
	// idPropName: string;
}

export interface IEntityFilesTab {
	type: entityTabType.files;
	title: string;
	icon?: IconProp;
	view: FunctionComponent<any>;
	parentEntityType: EntityType;
	// idPropName: string;
}

//5118TODO remove INewEntityDetailPopupTabConfigs

export interface INewEntityDetailPopupTabConfigs {
	new: true;
	popupType: EntityType | QuickAnnotationType;
	tabs: IEntityTab[];
}

export interface IEntityDetailPopupTabConfigs {
	new?: boolean;
	popupType: EntityType | QuickAnnotationType;
	viewModel: Function;
	tabs: IEntityTab[];
}

//forms
export interface IEntityFormDetailProps {
	vm: IViewModel<any>;
	editMethod: EditMethod;
	isEditing: boolean;
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
	setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
	setHasSavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
	id: string;
	parentId?: string;
	closeDetailPopup: (ignoreIsDirty?: boolean) => void;
	defaultEditingState?: boolean;
	crudCallbacks?: IViewModelFuncCallbacks<any>;
}

export interface IFormDetailProps extends IFormHeaderProps {
	header?: ReactNode;
}

// export interface IFormHeaderProps {
// 	vm: IViewModel<any>;
// 	headerText: string;
// 	editing: boolean;
// 	setEditing: (value: boolean) => void;
// 	closeDetailPopup: () => void;
// 	buttons: React.FC<IFormHeaderButtonsProps>
// }

export interface IFormHeaderButtonsProps {
	vm: IViewModel<any> | INewViewModel<any>;
	editing: boolean;
	setEditing: (value: boolean) => void;
	closeDetailPopup: () => void;
}

export interface IButtonsProps {
	vm: IViewModel<any> | INewViewModel<any>;
	editing: boolean;
	setEditing: (value: boolean) => void;
	canSaveOverride?: boolean;
	closeDetailPopup: (ignoreIsDirty?: boolean) => void;
	children?: React.ReactNode;
	excludeDelete?: boolean;
	id?: string;
}

export interface IFormHeaderProps {
	vm: IViewModel<any> | INewViewModel<any>;
	headerText: string | null;
	editing: boolean;
	setEditing: (value: boolean) => void;
	iconName: IconName;
	closeDetailPopup: (ignoreIsDirty?: boolean) => void;
	children?: React.ReactNode;
}

export interface IEntityFormHeaderProps extends Omit<IFormHeaderProps, 'iconName' | 'headerText'> {
	id?: string;
}
