import { OpportunityFormDetail } from './FormDetail/OpportunityFormDetail';
import { TaskFormDetail } from './FormDetail/TaskFormDetail';
import { EntityType } from './entityDetailPopup.enums';
import { FunctionComponent } from 'react';
import { IEntityDetailPopupTabConfigs, IEntityFormDetailProps, INewEntityDetailPopupTabConfigs, entityTabType } from './entityDetailPopup.interfaces';
import { CompanyFormDetail } from './FormDetail/CompanyFormDetail';
import CompanyRelatedViewModel from '../../viewmodels/Related/CompanyRelatedViewModel';
import { AddressFormDetail } from './FormDetail/CompanySubEntities/AddressFormDetail';
import { ContactFormDetail } from './FormDetail/CompanySubEntities/ContactFormDetail';
import { AnnotationFormDetail } from './FormDetail/CompanySubEntities/AnnotationFormDetail';
import { SubEntityListDetail } from '../EntityListDetail/SubEntityListDetail/SubEntityListDetail';
import { FileListView } from '../EntityListDetail/FileListView/FileListView';
import { faAddressBook, faCalendar, faCity, faListCheck, faPen, faPhone, faWaveSquare, faFiles } from '@fortawesome/pro-solid-svg-icons';
import { VisitingFrequencyFormDetail } from './FormDetail/VisitingFrequencyFormDetail';
import { AppointmentFormDetailCompany } from './FormDetail/AppointmentFormDetailCompany';
import { AppointmentFormDetailAgenda } from './FormDetail/AppointmentFormDetailAgenda';
import {
	AddressTableDefinition,
	AnnotationTableDefinition,
	AppointmentTableDefinition,
	CompanyOpportunityTableDefinition,
	ContactTableDefinition,
	ITableAttribute,
	TaskTableDefinition,
} from '../DynamicTable/dynamicTable.index';
import { QuickAnnotationForm } from './QuickAnnotationForm/QuickAnnotationForm';
import { SubEntityAnnotationEditorList } from '../EntityListDetail/SubEntityListDetail/SubEntityAnnotationEditorList';

export enum QuickAnnotationType {
	QuickAnnotation = 'QuickAnnotation',
}

export type IPopupEntityDictionary = Record<EntityType | QuickAnnotationType, IEntityDetailPopupTabConfigs | INewEntityDetailPopupTabConfigs>;

function removeColumn(tableAttributes: ITableAttribute[], propertyNamesToRemove: string[]): ITableAttribute[] {
	const columnsCopy = [...tableAttributes];

	propertyNamesToRemove.forEach((name) => {
		const columnIndex = columnsCopy.findIndex((column) => column.property.name === name);

		if (columnIndex >= 0) columnsCopy.splice(columnIndex, 1);
	});

	return columnsCopy;
}

export const CompanyDetailPopup: IEntityDetailPopupTabConfigs = {
	popupType: EntityType.Company,
	viewModel: CompanyRelatedViewModel,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.companies',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.company',
			view: CompanyFormDetail as FunctionComponent<IEntityFormDetailProps>,
			icon: faCity,
		},
		{
			type: entityTabType.header,
			title: 'tabHeaders.companyRelated',
		},
		{
			type: entityTabType.subEntityList,
			tableAttributes: AddressTableDefinition.tableAttributes,
			title: 'tabHeaders.addresses',
			view: SubEntityListDetail,
			collectionVMPropName: 'addresses',
			entityType: EntityType.Address,
			// idPropName: 'id',
			icon: faAddressBook,
		},
		{
			type: entityTabType.subEntityList,
			tableAttributes: ContactTableDefinition.tableAttributes,
			title: 'tabHeaders.contacts',
			view: SubEntityListDetail,
			collectionVMPropName: 'contacts',
			entityType: EntityType.Contact,
			// idPropName: 'id',
			icon: faAddressBook,
		},
		{
			type: entityTabType.subEntityList,
			tableAttributes: CompanyOpportunityTableDefinition.tableAttributes,
			title: 'tabHeaders.opportunities',
			view: SubEntityListDetail,
			collectionVMPropName: 'opportunities',
			entityType: EntityType.Opportunity,
			// idPropName: 'opportunity_Id',
			icon: faPhone,
		},
		{
			type: entityTabType.subEntityList,
			tableAttributes: removeColumn(TaskTableDefinition.tableAttributes, ['task_Company_Name', 'weeksDue']),
			title: 'tabHeaders.tasks',
			view: SubEntityListDetail,
			collectionVMPropName: 'tasks',
			entityType: EntityType.Task,
			// idPropName: 'task_Id',
			icon: faListCheck,
		},
		{
			type: entityTabType.subEntityList,
			tableAttributes: AppointmentTableDefinition.tableAttributes,
			title: 'tabHeaders.appointments',
			view: SubEntityListDetail,
			collectionVMPropName: 'appointments',
			entityType: EntityType.Appointment,
			// idPropName: 'entity_Id',
			icon: faCalendar,
		},
		{
			type: entityTabType.subEntityList,
			tableAttributes: AnnotationTableDefinition.tableAttributes,
			title: 'tabHeaders.annotations',
			view: SubEntityAnnotationEditorList,
			collectionVMPropName: 'annotations',
			entityType: EntityType.Annotation,
			// idPropName: 'entity_Id',
			icon: faPen,
		},
		{
			type: entityTabType.files,
			title: 'tabHeaders.files',
			view: FileListView,
			parentEntityType: EntityType.Company,
			icon: faFiles,
		},
	],
};

export const TaskDetailPopup: INewEntityDetailPopupTabConfigs = {
	new: true,
	popupType: EntityType.Task,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.tasks',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.task',
			view: TaskFormDetail,
			icon: faListCheck,
		},
		{
			type: entityTabType.files,
			title: 'tabHeaders.files',
			view: FileListView,
			parentEntityType: EntityType.Task,
			icon: faFiles,
		},
	],
};

export const OpportunityDetailPopup: INewEntityDetailPopupTabConfigs = {
	new: true,
	popupType: EntityType.Opportunity,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.opportunities',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.opportunity',
			view: OpportunityFormDetail,
			icon: faPhone,
		},
		{
			type: entityTabType.files,
			title: 'tabHeaders.files',
			view: FileListView,
			parentEntityType: EntityType.Opportunity,
			icon: faFiles,
		},
	],
};

export const AppointmentDetailPopup: INewEntityDetailPopupTabConfigs = {
	new: true,
	popupType: EntityType.Appointment,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.appointments',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.appointment',
			view: AppointmentFormDetailCompany,
			icon: faCalendar,
		},
		{
			type: entityTabType.files,
			title: 'tabHeaders.files',
			view: FileListView,
			parentEntityType: EntityType.Appointment,
			icon: faFiles,
		},
	],
};

export const AgendaDetailPopup: INewEntityDetailPopupTabConfigs = {
	new: true,
	popupType: EntityType.Agenda,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.agenda',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.appointment',
			view: AppointmentFormDetailAgenda,
			icon: faCalendar,
		},
		{
			type: entityTabType.files,
			title: 'tabHeaders.files',
			view: FileListView,
			parentEntityType: EntityType.Appointment,
			icon: faFiles,
		},
	],
};

export const AddressDetailPopup: INewEntityDetailPopupTabConfigs = {
	new: true,
	popupType: EntityType.Address,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.address',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.address',
			view: AddressFormDetail,
			icon: faAddressBook,
		},
	],
};

export const ContactDetailPopup: INewEntityDetailPopupTabConfigs = {
	new: true,
	popupType: EntityType.Contact,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.contact',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.contact',
			view: ContactFormDetail,
			icon: faAddressBook,
		},
	],
};

export const AnnotationDetailPopup: INewEntityDetailPopupTabConfigs = {
	new: true,
	popupType: EntityType.Annotation,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.annotation',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.annotation',
			view: AnnotationFormDetail,
			icon: faPen,
		},
	],
};

export const QuickAnnotationDetailPopup: INewEntityDetailPopupTabConfigs = {
	new: true,
	popupType: QuickAnnotationType.QuickAnnotation,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.quickAnnotations',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.quickAnnotation',
			view: QuickAnnotationForm,
			icon: faPen,
		},
	],
};

export const VisitingFrequencyDetailPopup: INewEntityDetailPopupTabConfigs = {
	new: true,
	popupType: EntityType.VisitingFrequency,
	tabs: [
		{
			type: entityTabType.header,
			title: 'tabHeaders.visitingFrequencies',
		},
		{
			type: entityTabType.form,
			title: 'tabHeaders.visitingFrequency',
			view: VisitingFrequencyFormDetail,
		},
	],
};

export const tabConfigurations: IPopupEntityDictionary = {
	[EntityType.Company]: CompanyDetailPopup,
	[EntityType.Task]: TaskDetailPopup,
	[EntityType.Opportunity]: OpportunityDetailPopup,
	[EntityType.Appointment]: AppointmentDetailPopup,
	[EntityType.Agenda]: AgendaDetailPopup,
	[EntityType.Address]: AddressDetailPopup,
	[EntityType.Contact]: ContactDetailPopup,
	[EntityType.Annotation]: AnnotationDetailPopup,
	[QuickAnnotationType.QuickAnnotation]: QuickAnnotationDetailPopup,
	[EntityType.VisitingFrequency]: VisitingFrequencyDetailPopup,
	[EntityType.File]: AppointmentDetailPopup,
};

export const entityIconDictionary = {
	[EntityType.Company]: faCity,
	[EntityType.Task]: faListCheck,
	[EntityType.Opportunity]: faPhone,
	[EntityType.Appointment]: faCalendar,
	[EntityType.Agenda]: faCalendar,
	[EntityType.Address]: faAddressBook,
	[EntityType.Contact]: faAddressBook,
	[EntityType.Annotation]: faPen,
	[QuickAnnotationType.QuickAnnotation]: faPen,
	[EntityType.VisitingFrequency]: faWaveSquare,
	[EntityType.File]: faFiles,
};
