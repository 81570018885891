import { api } from '../../api/_Executor';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { IVisitingFrequency } from '../../models/Model/VisitingFrequency';
import { ICompanyGroup } from '../../models/Model/CompanyGroup';
import { IRepresentative } from '../../models/Model/Representative';
import { IEntityValidation } from '../../models/Validation_EntityValidation/EntityValidation';
import { IFullFieldDefinition } from '../../models/_Custom/FullFieldDefinition';
import { IOpportunityStatusReason } from '../../models/Model/OpportunityStatusReason';
import { IAddressType } from '../../models/Model/AddressType';
import { ICountry } from '../../models/Model/Country';
import { IAppointmentType } from '../../models/Model/AppointmentType';
import {PostalCodeArea} from "../../models/Model/PostalCodeArea";

/** https://www.mcdonalds.com/nl/nl-nl/product/homestyle-wrap-honey-mustard.html */
function mcWrap(f: Function, path: string, q?: any) {
	return createAsyncThunk(`globals/${path}`, async () => {
		try {
			let response = await f(q);
			if (response) return response;
			return [];
		} catch (err) {
			console.error(err);
			return [];
		}
	});
}

interface IGlobalsSliceState {
	activeRepresentatives: IRepresentative[];
	addressTypes: IAddressType[];
	appointmentTypes: IAppointmentType[];
	companyGroups: ICompanyGroup[];
	countries: ICountry[];
	entityValidation: IEntityValidation[];
	fieldDefinitions: IFullFieldDefinition[] | undefined;
	opportunityStatusReasons: IOpportunityStatusReason[];
	representatives: IRepresentative[];
	visitingFrequencies: IVisitingFrequency[];
	organizationSettings: any;
	postalCodeAreas: PostalCodeArea[];
}

const initialState: IGlobalsSliceState = {
	activeRepresentatives: [],
	addressTypes: [],
	appointmentTypes: [],
	companyGroups: [],
	countries: [],
	entityValidation: [],
	fieldDefinitions: [],
	opportunityStatusReasons: [],
	representatives: [],
	visitingFrequencies: [],
	organizationSettings: {},
	postalCodeAreas: [],
};

export const globalsSlice = createSlice({
	name: 'globals',
	initialState,
	reducers: {
		clearGlobals: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(updateActiveRepresentatives.fulfilled, (state, action) => {
			state.activeRepresentatives = action.payload;
		});
		builder.addCase(updateAddressTypes.fulfilled, (state, action) => {
			state.addressTypes = action.payload;
		});
		builder.addCase(updateAppointmentTypes.fulfilled, (state, action) => {
			state.appointmentTypes = action.payload;
		});
		builder.addCase(updateCompanyGroups.fulfilled, (state, action) => {
			state.companyGroups = action.payload;
		});
		builder.addCase(updateCountries.fulfilled, (state, action) => {
			state.countries = action.payload;
		});
		builder.addCase(updateEntityValidation.fulfilled, (state, action) => {
			state.entityValidation = action.payload;
		});
		builder.addCase(updateFieldDefinitions.fulfilled, (state, action) => {
			state.fieldDefinitions = action.payload;
		});
		builder.addCase(updateOpportunityStatusReasons.fulfilled, (state, action) => {
			state.opportunityStatusReasons = action.payload;
		});
		builder.addCase(updateRepresentatives.fulfilled, (state, action) => {
			state.representatives = action.payload;
		});
		builder.addCase(updateVisitingFrequencies.fulfilled, (state, action) => {
			state.visitingFrequencies = action.payload;
		});
		builder.addCase(updateOrganizationSettings.fulfilled, (state, action) => {
			state.organizationSettings = action.payload;
		});
		builder.addCase(updatePostalCodeAreas.fulfilled, (state, action) => {
			state.postalCodeAreas = action.payload;
		});
	},
});

//Selectors
export const selectActiveRepresentatives = (s: RootState) => s.globals.activeRepresentatives;
export const selectAddressTypes = (s: RootState) => s.globals.addressTypes;
export const selectAppointmentTypes = (s: RootState) => s.globals.appointmentTypes;
export const selectCompanyGroups = (s: RootState) => s.globals.companyGroups;
export const selectCountries = (s: RootState) => s.globals.countries;
export const selectEntityValidations = (s: RootState) => s.globals.entityValidation;
export const selectFieldDefinitions = (s: RootState) => s.globals.fieldDefinitions;
export const selectOpportunityStatusReasons = (s: RootState) => s.globals.opportunityStatusReasons;
export const selectRepresentatives = (s: RootState) => s.globals.representatives;
export const selectVisitingFrequencies = (s: RootState) => s.globals.visitingFrequencies;
export const selectPostalCodeAreas = (s: RootState) => s.globals.postalCodeAreas;

export const updateActiveRepresentatives = mcWrap(api.representative.listAsync, 'updateActiveRepresentatives', { IsActive: true });

export const updateAddressTypes = mcWrap(api.addressType.listAsync, 'updateAddressTypes');

export const updateAppointmentTypes = mcWrap(api.appointmentType.listAsync, 'updateAppointmentTypes');

export const updateCompanyGroups = mcWrap(api.companyGroup.listAsync, 'updateCompanyGroups');

export const updateCountries = mcWrap(api.country.listAsync, 'updateCountries');

export const updateEntityValidation = mcWrap(api.special.getEntityValidationsAsync, 'updateEntityValidation');

export const updateFieldDefinitions = mcWrap(api.fieldDefinition.listAsync, 'updateFieldDefinitions');

export const updateOpportunityStatusReasons = mcWrap(api.opportunityStatusReason.listAsync, 'updateOpportunityStatusReasons');

export const updateRepresentatives = mcWrap(api.representative.listAsync, 'updateRepresentatives');

export const updateVisitingFrequencies = mcWrap(api.visitingFrequency.listAsync, 'updateVisitingFrequencies');

export const updateOrganizationSettings = mcWrap(api.organizationSettings.getCompact, 'updateOrganizationSettings');

export const updatePostalCodeAreas = mcWrap(api.postalCodeAreas.listAsync, 'updatePostalCodeAreas')

//EXPORTS
export const { clearGlobals } = globalsSlice.actions;
export default globalsSlice.reducer;
