import { Fragment, useCallback } from 'react';
import styles from './agenda.module.scss';
import { Button } from '../../../components';
import { IconCustom } from '../../../components/IconCustom/IconCustom';
import { IconCustomType } from '../../../components/IconCustom/IconCustom.definitions';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { faChevronsLeft, faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { entityIconDictionary } from '../../../components/EntityDetailPopup/entityDetailPopup.tabConfigurations';
import { EntityType } from '../../../components/EntityDetailPopup/entityDetailPopup.enums';
import { globals } from '../../../globals/environment';

const dateTimeFormatOptions = {
	weekday: 'short',
	year: 'numeric',
	month: 'short',
	day: '2-digit',
} as Intl.DateTimeFormatOptions;

export function SchedulerHeader(props: {
	view: 'month' | 'week' | 'work-week';
	setView: (view: 'month' | 'week' | 'work-week') => void;
	dateRange: { start: Date; end: Date };
	buttonHandlers: {
		backButton: () => void;
		forwardButton: () => void;
		todayButton: () => void;
		setDay: (day: Date) => void;
	};
	addIconOnClick: () => any;
}) {
	const { i18n } = useTranslation();

	return (
		<Fragment>
			<div className={styles.schedulerHeader}>
				<div className={styles.tabs}>
					<div
						className={`${styles.tab} ${props.view === 'month' && styles.active}`}
						onClick={() => props.setView('month')}
					>
						{t('month')}
					</div>
					<div
						className={`${styles.tab} ${props.view === 'week' && styles.active}`}
						onClick={() => props.setView('week')}
					>
						{t('week')}
					</div>
					<div
						className={`${styles.tab} ${props.view === 'work-week' && styles.active}`}
						onClick={() => props.setView('work-week')}
					>
						{t('work-week')}
					</div>
					{globals.environment.isDev() && (
						<Fragment>
							<div className={styles.tab}>Dev daySetter:</div>
							<input
								type="date"
								onChange={(e) => {
									props.buttonHandlers.setDay(new Date(e.target.value));
								}}
							/>
						</Fragment>
					)}
				</div>
				<div className={`${styles.icon} ${styles.right}`}>
					<IconCustom
						kind={IconCustomType.GEN_PLUS}
						onClick={props.addIconOnClick}
					/>
				</div>
				<div className={styles.rowSubHeader}>
					<div className={styles.title}>
						<div className={styles.listIcon}>
							<FontAwesomeIcon icon={entityIconDictionary[EntityType.Agenda]} />
						</div>
						<div className={styles.listTitle}>{t('agenda')}</div>
					</div>

					<div className={styles.dateRange}>
						{props.dateRange.start.toLocaleDateString(i18n.language, dateTimeFormatOptions)} -{' '}
						{props.dateRange.end.toLocaleDateString(i18n.language, dateTimeFormatOptions)}
					</div>
					<div className={styles.dateButtons}>
						<Button
							i18nKey="NA"
							theme="border"
							onClick={props.buttonHandlers.todayButton}
						>
							{t('today')}: {new Date().toLocaleDateString(i18n.language, dateTimeFormatOptions)}
						</Button>
						<Button
							i18nKey="NA"
							theme="border"
							onClick={props.buttonHandlers.backButton}
						>
							<FontAwesomeIcon icon={faChevronsLeft} />
						</Button>
						<Button
							i18nKey="NA"
							theme="border"
							onClick={props.buttonHandlers.forwardButton}
						>
							<FontAwesomeIcon icon={faChevronsRight} />
						</Button>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
