/* UNMODIFIED */
import { IFieldDefinition } from '../Model_Fields/FieldDefinition';
import { IFieldValue } from '../Model_Fields/FieldValue';
import { IFile } from '../Model/File';

export interface IModelBase {
	$type: string | null;
	id: string | null;
	rowVersion: Date | null;
	createdDate: Date | null;
	lastModifiedDate: Date | null;
	fieldDefinitions: IFieldDefinition[] | null;
	synchronizationKey: string | null;
	synchronizationDate: Date | null;
	extensionKey_Id: string | null;
	fieldValues: IFieldValue[] | null;
	files: IFile[] | null;
}

export class ModelBase implements IModelBase {
	public $type: string | null = null;
	public id: string | null = null;
	public rowVersion: Date | null = null;
	public createdDate: Date | null = null;
	public lastModifiedDate: Date | null = null;
	public fieldDefinitions: IFieldDefinition[] | null = null;
	public synchronizationKey: string | null = null;
	public synchronizationDate: Date | null = null;
	public extensionKey_Id: string | null = null;
	public fieldValues: IFieldValue[] | null = null;
	public files: IFile[] | null = null;
}
