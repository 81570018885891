import styles from './boundListView.module.scss';
import { useCallback, useState, Fragment, ReactNode, useEffect, Dispatch, SetStateAction, useMemo } from 'react';
import { DynamicTable } from '../../DynamicTable/DynamicTable';
import { ICollectionViewModel } from '../../../viewmodels/Collections/_collectionViewModel.interfaces';
import { SearchBar } from '../../SearchBar/SearchBar';
import { EntityDetailPopupConfigured } from '../../EntityDetailPopup/EntityDetailPopup';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { entityIconDictionary } from '../../EntityDetailPopup/entityDetailPopup.tabConfigurations';
import { EditMethod } from '../../EntityDetailPopup/entityDetailPopup.interfaces';
import { ITableAttribute } from '../../DynamicTable/DynamicTable.types';
import { useAppSelector } from '../../../store/hooks';
import { dateTimeTypeEnum } from '../../EntityDetailPopup/CustomField/CustomFieldEditor';
import { convertSortOrderItemArrayToObjectArray } from '../../../utilities/sortOrder';
import { FilterDropDownOption } from '../../FilterDropDown/FilterDropDown';
import Filters from './FiltersView';
import { IViewModelFuncCallbacks } from '../../../viewmodels/_viewModel.interfaces';

export interface IBoundListViewProps {
	className?;
	parentId?: string;
	cvm: ICollectionViewModel<any, any>;
	cvmRead: () => void;
	tableAttributes: ITableAttribute[];
	headerText?: string;
	entityType: EntityType;
	useCustomFields?: boolean;
	hidePlusButton?: boolean;
	VMInstanceName: string;
	setFilters?: Dispatch<SetStateAction<Array<FilterDropDownOption>>>;
	filters?: Array<FilterDropDownOption>;
	checkEmphasisFunction?: (item) => boolean;
	entityDetailFormCrudCallbacks?: IViewModelFuncCallbacks<any>;
}

type IFieldValuePropNameDictionary = Record<EntityType, string>;

export const fieldValuePropNames: IFieldValuePropNameDictionary = {
	[EntityType.Company]: 'entity_FieldValues',
	[EntityType.Task]: 'task_FieldValues',
	[EntityType.Opportunity]: 'opportunity_FieldValues',
	[EntityType.Appointment]: 'fieldValues',
	[EntityType.Agenda]: 'fieldValues',
	[EntityType.Address]: 'entity_FieldValues',
	[EntityType.Contact]: 'fieldValues',
	[EntityType.Annotation]: 'entity_FieldValues',
	[EntityType.VisitingFrequency]: 'entity_FieldValues',
	[EntityType.File]: 'entity_FieldValues',
};

export const BoundListView = (props: IBoundListViewProps) => {
	const [currentId, setCurrentId] = useState<string>();
	const [popupMethod, setPopupMethod] = useState<EditMethod>(EditMethod.update);
	const [tableAttributes, setTableAttributes] = useState(props.tableAttributes);
	const [tableItems, setTableItems] = useState(props.cvm.items);

	const boundListViewClassName = useMemo(() => {
		let className = styles.boundListView;
		if (props.className !== undefined) className += ` ${props.className}`;

		return className;
	}, [props.className]);

	const [FDs, currentRepresentative] = useAppSelector((store) => [store.globals.fieldDefinitions, store.currentRepresentative.currentRepresentative]);

	const rowClickFunction = useCallback(
		(tableItem: any) => {
			setCurrentId(tableItem[props.cvm.pkName]);
		},
		[props.cvm.pkName]
	);

	const closeFunction = useCallback(
		async (changed?: boolean) => {
			setCurrentId(undefined);
			if (changed) {
				await props.cvmRead();
				setTableItems(props.cvm.items);
			}
		},
		[props]
	);

	const onRowClick = useCallback(
		(tableItem) => () => {
			setPopupMethod(EditMethod.update);
			rowClickFunction(tableItem);
		},
		[rowClickFunction]
	);

	const onPlusClick = useCallback(() => {
		setCurrentId('');
		setPopupMethod(EditMethod.create);
	}, []);

	const onChangeRepresentative = useCallback(() => {
		if (props.cvm.switchRepresentative) {
			props.cvm.switchRepresentative();
			setTableItems(props.cvm.items);
		}
	}, [props.cvm]);

	useEffect(() => {
		const newTableAttributes = [...props.tableAttributes];

		if (FDs !== undefined && props.useCustomFields) {
			const FDsForCurrentEntityType = FDs?.filter((FD) => FD.entityType_Name === EntityType[props.entityType]);
			const FDTableAttributes: ITableAttribute[] = FDsForCurrentEntityType.map((FD) => {
				let datetimeType;
				if (FD.$type === 'fieldDefinitionDateTime') {
					datetimeType = dateTimeTypeEnum[FD.type!];
				}

				return {
					headTitle: FD.label!,
					property: { name: FD.id! },
					type: datetimeType,
					dontTranslateHeadTitle: true,
					dontUseSorting: true,
				};
			});

			newTableAttributes.push(...FDTableAttributes);
		}

		setTableAttributes(newTableAttributes);
	}, [props.tableAttributes, props.useCustomFields, props.entityType, FDs]);

	useEffect(() => {
		const newTableItems = props.cvm.items;

		if (props.useCustomFields) {
			const propName = fieldValuePropNames[props.entityType];

			newTableItems.forEach((item) => {
				const fieldValues = item[propName];

				if (fieldValues !== undefined)
					fieldValues.forEach((element) => {
						const [fieldValueId, fieldValue] = element.split(/:(.+)/);

						item[fieldValueId.toLowerCase()] = fieldValue;
					});
			});
		}

		setTableItems(newTableItems);
	}, [props.cvm.items, props.useCustomFields, props.entityType, FDs]);

	const cvmRead = props.cvmRead;

	useEffect(() => {
		if (currentId === undefined) {
			cvmRead();
			setTableItems(props.cvm.items);
		}
	}, [cvmRead]);

	useEffect(() => {
		onChangeRepresentative();
	}, [cvmRead, currentRepresentative]);

	return (
		<Fragment>
			<div className={boundListViewClassName}>
				<div className={styles.topBar}>
					<div className={styles.top}>
						<div className={styles.headerText}>
							<span className={styles.label}>
								<FontAwesomeIcon
									className={styles.icon}
									icon={entityIconDictionary[props.entityType]}
								/>
								<span className={styles.name}>{props.headerText}</span>
							</span>
						</div>
						<div className={styles.searchBox}>
							{props.cvm.isSearchable && (
								<SearchBar
									border
									searchCallback={props.cvm.search}
								/>
							)}
						</div>
						{!props.hidePlusButton && (
							<FontAwesomeIcon
								className={styles.addButton}
								icon={faPlusCircle}
								size="xl"
								onClick={onPlusClick}
							/>
						)}
					</div>
					<div className={styles.bottom}>
						<Filters {...props} />
					</div>
				</div>

				<div className={styles.tableContainer}>
					<DynamicTable
						tableAttributes={tableAttributes}
						tableItems={tableItems}
						onRowClick={onRowClick}
						finalItemVisibleCallback={props.cvm.pageNext}
						thClickHandler={props.cvm.toggleSortOrder}
						sortOrderObjectArray={convertSortOrderItemArrayToObjectArray(props.cvm.query.order)}
						checkEmphasisFunction={props.checkEmphasisFunction}
					/>
				</div>
			</div>

			{currentId !== undefined && (
				<EntityDetailPopupConfigured
					new={props.entityType !== EntityType.Company}
					id={currentId}
					parentId={props.parentId}
					editMethod={popupMethod}
					popupEntityType={props.entityType}
					closeFunction={closeFunction}
					viewModelInstanceName={`${props.VMInstanceName}`}
					crudCallbacks={props.entityDetailFormCrudCallbacks}
				/>
			)}
		</Fragment>
	);
};
