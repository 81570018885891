import { useCallback } from 'react';
import { ISubEntityListDetailProps } from '../entityListView.interfaces';
import styles from '../entityListView.module.scss';
import { BoundListView } from '../BoundListView/BoundListView';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';

export function SubEntityListDetail(props: ISubEntityListDetailProps) {
	const cvm = props.vm[props.collectionVMPropName];

	const cvmRead = useCallback(() => {
		cvm.read(props.id);
	}, [props.id, props.collectionVMPropName]);

	return (
		<div className={styles.container}>
			<BoundListView
				className={props.className}
				parentId={props.id}
				cvm={cvm}
				cvmRead={cvmRead}
				tableAttributes={props.tableAttributes}
				entityType={props.entityType}
				VMInstanceName={EntityType[props.entityType]}
				headerText={props.vm.getProperty('name')}
				useCustomFields
				entityDetailFormCrudCallbacks={props.entityDetailFormCrudCallbacks}
			/>
		</div>
	);
}
