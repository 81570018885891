import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import { INewViewModel, IViewModelFuncCallbacks, IViewModelFuncOverrides, ModelFromEntity } from './_viewModel.interfaces';
import { QuickAppointmentAnnotation } from '../models/BusinessObjects_Model/QuickAppointmentAnnotation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getObjProps, isNotNilOrEmpty } from '../utilities/utils';
import { useAppSelector } from '../store/hooks';
import authProvider from '../utilities/authProvider';
import { basicVMFunctions } from './_BasicVMFunctions';
import useEntityInputValidator, { ValidatorObject } from '../hooks/validation/entityInputValidator';

export interface IQuickAnnotationViewModel extends Omit<INewViewModel<QuickAppointmentAnnotation>, 'refresh' | 'delete' | 'fieldDefinitions'> {}

type quickAnnotationModel = ModelFromEntity<QuickAppointmentAnnotation>;

const propertyNames = getObjProps<QuickAppointmentAnnotation>();

function checkRequired(value) {
	return isNotNilOrEmpty(value);
}

const validationRules: ValidatorObject = {
	[propertyNames.representativeId]: [checkRequired],
	[propertyNames.companyId]: [checkRequired],
	[propertyNames.annotationText]: [checkRequired],
	[propertyNames.startTime]: [checkRequired],
	[propertyNames.endTime]: [checkRequired],
	[propertyNames.isFollowUp]: [checkRequired],
};

const apiFuncs = {
	update: api.quickAnnotation.updateAsync,
};

function generateModelInstance(companyId: string, representativeId?: string, organizationSettings?: any): quickAnnotationModel {
	const now = new Date();
	const future = new Date();
	future.setMinutes(now.getMinutes() + 15);

	const emptyQuickAppointmentAnnotation = new QuickAppointmentAnnotation();

	emptyQuickAppointmentAnnotation.representativeId = authProvider.getUserId()!;
	emptyQuickAppointmentAnnotation.companyId = companyId;
	emptyQuickAppointmentAnnotation.startTime = now;
	emptyQuickAppointmentAnnotation.endTime = future;
	emptyQuickAppointmentAnnotation.isFollowUp = false;

	if (organizationSettings !== undefined) {
		if (isNotNilOrEmpty(organizationSettings.quickAnnotation_DefaultAppointmentTypeId)) {
			emptyQuickAppointmentAnnotation.appointmentTypeId = organizationSettings.quickAnnotation_DefaultAppointmentTypeId;
		}

		emptyQuickAppointmentAnnotation.appointmentSubject = organizationSettings.quickAnnotation_DefaultAppointmentSubject;
		emptyQuickAppointmentAnnotation.isFollowUp = false;
	}

	const model = basicVMFunctions.createModelFromEntity(emptyQuickAppointmentAnnotation);

	return model;
}

export default function useQuickAnnotationViewModel(
	companyId: string,
	funcCallbacks?: IViewModelFuncCallbacks<QuickAppointmentAnnotation>,
	funcOverrides?: IViewModelFuncOverrides<QuickAppointmentAnnotation>
): IQuickAnnotationViewModel {
	const validator = useEntityInputValidator(EntityType.quickAppointmentAnnotation, validationRules);

	const organizationSettings = useAppSelector((state) => state.globals.organizationSettings);

	const [model, setModel] = useState(generateModelInstance(companyId, organizationSettings));

	const isValid = useMemo(() => basicVMFunctions.checkIfModelIsValid(model), [model]);

	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const setModelPropertyValue = useCallback(
		(propertyName: keyof QuickAppointmentAnnotation) => (value) =>
			basicVMFunctions.setModelPropertyValue<QuickAppointmentAnnotation>(
				propertyName,
				value,
				model,
				setModel,
				validator,
				funcCallbacks?.setModelPropertyValue
			),
		[funcCallbacks?.setModelPropertyValue, model, validator]
	);

	const update = useCallback(
		async () =>
			await basicVMFunctions.updateEntity<QuickAppointmentAnnotation>(
				true,
				apiFuncs.update,
				model,
				[],
				setHasSavedChanges,
				async () => {},
				funcCallbacks?.update
			),

		[funcCallbacks?.update, model]
	);

	// useEffect(() => {
	// 	basicVMFunctions.validateAllProperties(model, setModel, validator);
	// }, [model, validator]);

	return {
		new: true,
		model,
		isValid,
		hasChanges: true,
		hasSavedChanges,
		update: funcOverrides?.update ? () => funcOverrides.update!(model, true, []) : update,
		setModelPropertyValue: funcOverrides?.setModelPropertyValue ? funcOverrides.setModelPropertyValue(model, setModel, validator) : setModelPropertyValue,
	};
}
