import React, {MouseEventHandler} from "react";
import styles from './buttonResetFilter.module.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface IButtonResetFilterProps {
    onClick: MouseEventHandler,
    children: React.ReactNode,
    disabled: boolean
}

const ButtonResetFilter = ({onClick, children, disabled}: IButtonResetFilterProps) => {
    return (
        <button disabled={disabled} className={`${styles.button} ${disabled ? styles.disabledButton : ''}`} onClick={onClick}>
            <FontAwesomeIcon className={styles.icon} icon="rotate-left" />
            {children}
        </button>
    )
}

export default ButtonResetFilter