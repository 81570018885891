import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import { VisitingFrequency } from '../models/Model/VisitingFrequency';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import { BaseViewModel } from './_BaseViewModel';
import { basicVMFunctions } from './_BasicVMFunctions';
import { INewViewModel, IViewModel, IViewModelFuncCallbacks, IViewModelFuncOverrides, ModelFromEntity } from './_viewModel.interfaces';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useEntityInputValidator from '../hooks/validation/entityInputValidator';
import { useAppSelector } from '../store/hooks';
import authProvider from '../utilities/authProvider';
import { UserManager } from '../utilities/UserManager';
import { isNilOrEmpty } from '../utilities/utils';

export interface IVisitingFrequencyViewModel extends INewViewModel<VisitingFrequency> {}

type VisitingFrequencyModel = ModelFromEntity<VisitingFrequency>;

const apiFuncs = {
	read: api.visitingFrequency.getByIdAsync,
	update: api.visitingFrequency.updateAsync,
	delete: api.visitingFrequency.deleteByIdAsync,
};

function generateModelInstance(fieldDefinitions?: FieldDefinition[]): VisitingFrequencyModel {
	const visitingFrequency = new VisitingFrequency();
	const model = basicVMFunctions.createModelFromEntity<VisitingFrequency>(visitingFrequency, fieldDefinitions);

	return model;
}

//GEDEELTELIJK GETEST
export default function useVisitingFrequencyViewModel(
	id?: string,
	funcCallbacks?: IViewModelFuncCallbacks<VisitingFrequency>,
	funcOverrides?: IViewModelFuncOverrides<VisitingFrequency>
): IVisitingFrequencyViewModel {
	const validator = useEntityInputValidator(EntityType.visitingFrequency);

	const allFieldDefinitions = useAppSelector((state) => state.globals.fieldDefinitions ?? []);
	const fieldDefinitions = useMemo(
		() => basicVMFunctions.filterFieldDefinitionsByEntityType_Name(allFieldDefinitions, 'VisitingFrequency'),
		[allFieldDefinitions]
	);

	const [model, setModel] = useState<VisitingFrequencyModel>(generateModelInstance(fieldDefinitions));
	const [unchangedModel, setUnchangedModel] = useState<VisitingFrequencyModel>(generateModelInstance(fieldDefinitions));

	const isValid = useMemo(() => basicVMFunctions.checkIfModelIsValid(model), [model]);

	const hasChanges = useMemo(() => JSON.stringify(model) !== JSON.stringify(unchangedModel) || isNilOrEmpty(model.id.value), [model, unchangedModel]);
	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const setModelPropertyValue = useCallback(
		(propertyName: keyof VisitingFrequency) => (value) =>
			basicVMFunctions.setModelPropertyValue<VisitingFrequency>(propertyName, value, model, setModel, validator, funcCallbacks?.setModelPropertyValue),
		[funcCallbacks?.setModelPropertyValue, model, validator]
	);

	const refresh = useCallback(
		async () =>
			await basicVMFunctions.readEntity<VisitingFrequency>(apiFuncs.read, fieldDefinitions, setModel, setUnchangedModel, id, funcCallbacks?.refresh),
		[fieldDefinitions, funcCallbacks?.refresh, id]
	);

	const update = useCallback(
		async () =>
			await basicVMFunctions.updateEntity<VisitingFrequency>(
				hasChanges,
				apiFuncs.update,
				model,
				fieldDefinitions,
				setHasSavedChanges,
				refresh,
				funcCallbacks?.update
			),
		[fieldDefinitions, funcCallbacks?.update, hasChanges, model, refresh]
	);

	const deleteVisitingFrequency = useCallback(
		async () => await basicVMFunctions.deleteEntity(apiFuncs.delete, setHasSavedChanges, id, funcCallbacks?.delete),
		[funcCallbacks?.delete, id]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	// useEffect(() => {
	// 	basicVMFunctions.validateAllProperties(model, setModel, validator);
	// }, [model, validator]);

	return {
		new: true,
		model,
		fieldDefinitions: fieldDefinitions,
		isValid,
		hasChanges,
		hasSavedChanges,
		refresh: funcOverrides?.refresh ? funcOverrides.refresh : refresh,
		update: funcOverrides?.update ? () => funcOverrides.update!(model, hasChanges, fieldDefinitions) : update,
		delete: funcOverrides?.delete ? funcOverrides.delete : deleteVisitingFrequency,
		setModelPropertyValue: funcOverrides?.setModelPropertyValue ? funcOverrides.setModelPropertyValue(model, setModel, validator) : setModelPropertyValue,
	};
}
