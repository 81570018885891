import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import useEntityInputValidator from '../hooks/validation/entityInputValidator';
import { Contact } from '../models/Model/Contact';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import { useAppSelector } from '../store/hooks';
import { BaseViewModel } from './_BaseViewModel';
import { basicVMFunctions } from './_BasicVMFunctions';
import { INewViewModel, IViewModel, IViewModelFuncCallbacks, IViewModelFuncOverrides, ModelFromEntity } from './_viewModel.interfaces';
import { isNilOrEmpty } from '../utilities/utils';

export interface IContactViewModel extends INewViewModel<Contact> {}
type ContactModel = ModelFromEntity<Contact>;

const apiFuncs = {
	read: api.contact.getByIdAsync,
	update: api.contact.updateAsync,
	delete: api.contact.deleteByIdAsync,
};

function generateModelInstance(companyId?: string, fieldDefinitions?: FieldDefinition[]): ContactModel {
	const contact = new Contact();
	const model = basicVMFunctions.createModelFromEntity(contact, fieldDefinitions);

	if (companyId) {
		model.company_Id.value = companyId;
	}

	return model;
}

export function useContactViewModel(
	id?: string,
	companyId?: string,
	funcCallbacks?: IViewModelFuncCallbacks<Contact>,
	funcOverrides?: IViewModelFuncOverrides<Contact>
): IContactViewModel {
	const validator = useEntityInputValidator(EntityType.contact);

	const allFieldDefinitions = useAppSelector((state) => state.globals.fieldDefinitions ?? []);
	const fieldDefinitions = useMemo(() => basicVMFunctions.filterFieldDefinitionsByEntityType_Name(allFieldDefinitions, 'Contact'), [allFieldDefinitions]);

	const [model, setModel] = useState<ContactModel>(generateModelInstance(companyId, fieldDefinitions));
	const [unchangedModel, setUnchangedModel] = useState<ContactModel>(generateModelInstance(companyId, fieldDefinitions));

	const isValid = useMemo(() => basicVMFunctions.checkIfModelIsValid(model), [model]);

	const hasChanges = useMemo(() => JSON.stringify(model) !== JSON.stringify(unchangedModel) || isNilOrEmpty(model.id.value), [model, unchangedModel]);
	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const setModelPropertyValue = useCallback(
		(propertyName: keyof Contact) => (value) =>
			basicVMFunctions.setModelPropertyValue<Contact>(propertyName, value, model, setModel, validator, funcCallbacks?.setModelPropertyValue),
		[funcCallbacks?.setModelPropertyValue, model, validator]
	);

	const refresh = useCallback(
		async () => await basicVMFunctions.readEntity<Contact>(apiFuncs.read, fieldDefinitions, setModel, setUnchangedModel, id, funcCallbacks?.refresh),
		[fieldDefinitions, funcCallbacks?.refresh, id]
	);

	const update = useCallback(
		async () =>
			await basicVMFunctions.updateEntity<Contact>(
				hasChanges,
				apiFuncs.update,
				model,
				fieldDefinitions,
				setHasSavedChanges,
				refresh,
				funcCallbacks?.update
			),
		[fieldDefinitions, funcCallbacks?.update, hasChanges, model, refresh]
	);

	const deleteContact = useCallback(
		async () => await basicVMFunctions.deleteEntity(apiFuncs.delete, setHasSavedChanges, id, funcCallbacks?.delete),
		[funcCallbacks?.delete, id]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	// useEffect(() => {
	// 	basicVMFunctions.validateAllProperties(model, setModel, validator);
	// }, [model, validator]);

	return {
		new: true,
		model,
		fieldDefinitions: fieldDefinitions,
		isValid,
		hasChanges,
		hasSavedChanges,
		refresh: funcOverrides?.refresh ? funcOverrides.refresh : refresh,
		update: funcOverrides?.update ? () => funcOverrides.update!(model, hasChanges, fieldDefinitions) : update,
		delete: funcOverrides?.delete ? funcOverrides.delete : deleteContact,
		setModelPropertyValue: funcOverrides?.setModelPropertyValue ? funcOverrides.setModelPropertyValue(model, setModel, validator) : setModelPropertyValue,
	};
}
