import { IRepresentativeDto } from '../model/EntityDtos/RepresentativeDto';
import authProvider from './authProvider';

export interface IUserManager {
	/*
	 * Gets the token for authenticating users
	 */
	getToken(): string;
	getCurrentUser(): IRepresentativeDto | undefined;
	/*
	 * Get the Id for the currently active representative
	 */
	getCurrentUserId(): string | null | undefined;
}

export class UserManager implements IUserManager {
	static CurrentRepresentative: IRepresentativeDto | undefined;

	getToken(): string {
		return authProvider.getTokenFromCookie() ?? '';
	}

	getCurrentUser(): IRepresentativeDto | undefined {
		return UserManager.CurrentRepresentative;
	}

	getCurrentUserId(): string | null | undefined {
		const currentUser = this.getCurrentUser();
		return currentUser !== undefined ? currentUser.id : authProvider.getUserId();
	}
}
