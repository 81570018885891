import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { DateTimeType } from '../../InputFields/inputField.types';
import { updateCompanies } from '../../../store/reducers/CompanySlice';
import { FormDetail } from './_FormDetail';
import { Opportunity } from '../../../models/Model/Opportunity';
import { IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { InfoGroup } from '../../InfoBlocks';
import { DropdownEntityType } from '../../InputFields/BoundDropDown/types';
import { CustomFieldEditor } from '../CustomField/CustomFieldEditor';
import { hasModule, moduleNames } from '../../../utilities/authProvider';
import { getObjProps } from '../../../utilities/utils';
import useOpportunityViewModel from '../../../viewmodels/OpportunityViewModel';
import { basicVMFunctions } from '../../../viewmodels/_BasicVMFunctions';

interface IOpportunityFormDetailProps extends IEntityFormDetailProps {}

const hasOpportunityStatusReasonModule = hasModule(moduleNames.opportunityStatusReason);

const propertyNames = getObjProps<Opportunity>();

export const OpportunityFormDetail = (props: IOpportunityFormDetailProps) => {
	const [id, setId] = useState(props.id);

	const crudCallbacks = useMemo(() => {
		const additionalCallbacks = {
			update: (entity) => {
				basicVMFunctions.createEntityCallback(entity, id, setId);
			},
		};
		if (props.crudCallbacks) return basicVMFunctions.appendCallbacks(props.crudCallbacks, additionalCallbacks);
		else return additionalCallbacks;
	}, [id, props.crudCallbacks]);

	useEffect(() => {
		setId(props.id);
	}, [props.id]);

	const viewModel = useOpportunityViewModel(id, props.parentId, crudCallbacks);

	useEffect(() => {
		props.setIsDirty(viewModel.hasChanges);
	}, [props, viewModel.hasChanges]);

	useEffect(() => {
		props.setHasSavedChanges(viewModel.hasSavedChanges);
	}, [props, viewModel.hasSavedChanges]);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<FormDetail
			vm={viewModel}
			headerText={viewModel.model.subject.value}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName="phone"
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('connection')}
			>
				<BoundInputRows.PresetDropDownRow
					label={t('company')}
					new
					modelProperty={viewModel.model.company_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.company_Id)}
					DDEntityType={DropdownEntityType.companies}
					readonly={!props.isEditing}
				/>
			</InfoGroup>

			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('opportunityData')}
			>
				<BoundInputRows.TextRow
					label={t('opportunityAttributes.subject')}
					new
					modelProperty={viewModel.model.subject}
					setProperty={viewModel.setModelPropertyValue(propertyNames.subject)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.NumberRow
					allowDecimals
					label={t('opportunityAttributes.amount')}
					new
					modelProperty={viewModel.model.amount}
					setProperty={viewModel.setModelPropertyValue(propertyNames.amount)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.NumberRow
					label={t('opportunityAttributes.estimatedSuccessRate')}
					new
					modelProperty={viewModel.model.estimatedSuccessRate}
					setProperty={viewModel.setModelPropertyValue(propertyNames.estimatedSuccessRate)}
					readonly={!props.isEditing}
					min={0}
					max={100}
				/>
				<BoundInputRows.DateTimeRow
					label={t('opportunityAttributes.estimatedClosingDate')}
					new
					modelProperty={viewModel.model.estimatedClosingDate}
					setProperty={viewModel.setModelPropertyValue(propertyNames.estimatedClosingDate)}
					type={DateTimeType.date}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextareaRow
					label={t('opportunityAttributes.description')}
					new
					modelProperty={viewModel.model.description}
					setProperty={viewModel.setModelPropertyValue(propertyNames.description)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					label={t('opportunityAttributes.representative')}
					new
					modelProperty={viewModel.model.representative_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.representative_Id)}
					DDEntityType={DropdownEntityType.activeRepresentatives}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					label={t('representativeAttributes.createdBy')}
					new
					modelProperty={viewModel.model.createdBy_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.createdBy_Id)}
					DDEntityType={DropdownEntityType.activeRepresentatives}
					readonly
				/>
				<BoundInputRows.PresetDropDownRow
					label={t('opportunityAttributes.status')}
					new
					modelProperty={viewModel.model.opportunityStatus}
					setProperty={viewModel.setModelPropertyValue(propertyNames.opportunityStatus)}
					DDEntityType={DropdownEntityType.opportunityStatuses}
					readonly={!props.isEditing}
				/>
				{hasOpportunityStatusReasonModule && (
					<Fragment>
						<BoundInputRows.PresetDropDownRow
							label={t('opportunityAttributes.statusReason')}
							new
							modelProperty={viewModel.model.opportunityStatusReason_Id}
							setProperty={viewModel.setModelPropertyValue(propertyNames.opportunityStatusReason_Id)}
							DDEntityType={DropdownEntityType.opportunityStatusReasons}
							readonly={!props.isEditing}
						/>
						<BoundInputRows.TextRow
							label={t('opportunityAttributes.statusClarification')}
							new
							modelProperty={viewModel.model.statusClarification}
							setProperty={viewModel.setModelPropertyValue(propertyNames.statusClarification)}
							readonly={!props.isEditing}
						/>
					</Fragment>
				)}
			</InfoGroup>

			<CustomFieldEditor
				vm={viewModel}
				editing={props.isEditing}
			/>
		</FormDetail>
	);
};
