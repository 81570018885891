import { useCallback, useEffect, useState } from 'react';
import styles from './SSOLoginButton.module.scss';
import logo from './ms-logo.svg';
import { getProvider, getMicrosoftLoginUrl, ProviderNames } from '../../api/OidcApi';

export const SSOLoginButton = () => {
	const [loading, setLoading] = useState(false);
	const [msProvider, setMSProvider] = useState<any>();

	const clickHandler = useCallback(() => {
		setLoading(true);
		getMicrosoftLoginUrl(msProvider).then((url) => {
			if (url) window.location.href = url;
			setLoading(false);
		});
	}, [msProvider]);

	useEffect(() => {
		const handleMSProvider = async () => {
			const msProvider = await getProvider(ProviderNames['MS-COMMON']);

			setMSProvider(msProvider);
		};

		handleMSProvider();
	}, []);

	if (msProvider !== undefined)
		return (
			<div
				className={`${styles.SSOButton} ${loading && styles.loading}`}
				onClick={clickHandler}
			>
				<img
					src={logo}
					alt="microsoft logo"
					className={styles.msLogo}
				/>
				Sign in with Microsoft
			</div>
		);
	else return null;
};
