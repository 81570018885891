import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import { Task } from '../models/Model/Task';
import authProvider from '../utilities/authProvider';
import { UserManager } from '../utilities/UserManager';
import { INewViewModel, IViewModelFuncCallbacks, IViewModelFuncOverrides, ModelFromEntity } from './_viewModel.interfaces';
import { basicVMFunctions } from './_BasicVMFunctions';
import { useAppSelector } from '../store/hooks';
import useEntityInputValidator from '../hooks/validation/entityInputValidator';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import { isNilOrEmpty } from '../utilities/utils';
export interface ITaskViewModel extends INewViewModel<Task> {
	toggleTaskCompleteCommand(): void;
}

type TaskModel = ModelFromEntity<Task>;

const apiFuncs = {
	read: api.task.getByIdAsync,
	update: api.task.updateAsync,
	delete: api.task.deleteByIdAsync,
};

const userManager = new UserManager();

function generateModelInstance(companyId?: string, representativeId?: string, fieldDefinitions?: FieldDefinition[]): TaskModel {
	const task = new Task();
	const model = basicVMFunctions.createModelFromEntity<Task>(task, fieldDefinitions);

	model.createdBy_Id.value = authProvider.getUserId() ?? null;
	model.assignedTo_Id.value = userManager.getCurrentUserId() ?? null;

	if (representativeId) {
		model.createdBy_Id.value = representativeId;
		model.assignedTo_Id.value = representativeId;
	}

	if (companyId) {
		model.company_Id.value = companyId;
	}

	return model;
}

export function useTaskViewModel(
	id?: string,
	companyId?: string,
	funcCallbacks?: IViewModelFuncCallbacks<Task>,
	funcOverrides?: IViewModelFuncOverrides<Task>
): ITaskViewModel {
	const validator = useEntityInputValidator(EntityType.task);

	const allFieldDefinitions = useAppSelector((state) => state.globals.fieldDefinitions ?? []);
	const fieldDefinitions = useMemo(() => basicVMFunctions.filterFieldDefinitionsByEntityType_Name(allFieldDefinitions, 'Task'), [allFieldDefinitions]);

	const [model, setModel] = useState<TaskModel>(generateModelInstance(companyId, undefined, fieldDefinitions));
	const [unchangedModel, setUnchangedModel] = useState<TaskModel>(generateModelInstance(companyId, undefined, fieldDefinitions));

	const isValid = useMemo(() => basicVMFunctions.checkIfModelIsValid(model), [model]);

	const hasChanges = useMemo(() => JSON.stringify(model) !== JSON.stringify(unchangedModel) || isNilOrEmpty(model.id.value), [model, unchangedModel]);
	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const setModelPropertyValue = useCallback(
		(propertyName: keyof Task) => (value) =>
			basicVMFunctions.setModelPropertyValue<Task>(propertyName, value, model, setModel, validator, funcCallbacks?.setModelPropertyValue),
		[funcCallbacks?.setModelPropertyValue, model, validator]
	);

	const refresh = useCallback(async () => {
		await basicVMFunctions.readEntity<Task>(apiFuncs.read, fieldDefinitions, setModel, setUnchangedModel, id, funcOverrides?.refresh);
	}, [fieldDefinitions, id, funcOverrides?.refresh]);

	const update = useCallback(
		async () =>
			await basicVMFunctions.updateEntity<Task>(hasChanges, apiFuncs.update, model, fieldDefinitions, setHasSavedChanges, refresh, funcCallbacks?.update),

		[hasChanges, model, refresh, fieldDefinitions, funcCallbacks?.update]
	);

	const deleteTask = useCallback(
		async () => await basicVMFunctions.deleteEntity(apiFuncs.delete, setHasSavedChanges, id, funcCallbacks?.delete),

		[funcCallbacks?.delete, id]
	);

	const toggleTaskCompleteCommand = useCallback(async () => {
		if (isValid === false) return;

		const shouldComplete = !model.isCompleted.value;

		if (shouldComplete) {
			setModelPropertyValue('completeDate')(new Date());
			setModelPropertyValue('isCompleted')(shouldComplete);

			await update();
		}
	}, [isValid, model.isCompleted, setModelPropertyValue, update]);

	useEffect(() => {
		refresh();
	}, [refresh]);

	// useEffect(() => {
	// 	basicVMFunctions.validateAllProperties(model, setModel, validator);
	// }, [model, validator]);

	return {
		new: true,
		model: model,
		fieldDefinitions,
		isValid,
		hasChanges,
		hasSavedChanges,
		refresh: funcOverrides?.refresh ? funcOverrides.refresh : refresh,
		update: funcOverrides?.update ? () => funcOverrides.update!(model, hasChanges, fieldDefinitions) : update,
		delete: funcOverrides?.delete ? funcOverrides.delete : deleteTask,
		setModelPropertyValue: funcOverrides?.setModelPropertyValue ? funcOverrides.setModelPropertyValue!(model, setModel, validator) : setModelPropertyValue,
		toggleTaskCompleteCommand,
	};
}
