import { Fragment, useCallback, useMemo, useState } from 'react';

import styles from './inputFields.module.scss';
import { InputAction } from './InputAction';
import { faCircleDown, faCircleUp } from '@fortawesome/pro-light-svg-icons';
import { ITextareaProps } from './inputField.types';

function TextareaComponent(props: ITextareaProps) {
	const rows = useMemo(() => {
		if (props.expanded) {
			return props.rowsExpanded !== undefined ? props.rowsExpanded : 20;
		} else return props.rows !== undefined ? props.rows : 3;
	}, [props.expanded, props.rows, props.rowsExpanded]);

	return (
		<textarea
			required={props.required}
			className={`${styles.input} ${styles.textarea} ${props.hasError && styles.invalid}`}
			rows={rows}
			cols={32}
			value={props.value || ''}
			onChange={(e) => props.onChange(e.target.value)}
			readOnly={props.readOnly}
		/>
	);
}

export default TextareaComponent;
