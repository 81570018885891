import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import useEntityInputValidator from '../hooks/validation/entityInputValidator';
import { Annotation } from '../models/Model/Annotation';
import { Appointment } from '../models/Model/Appointment';
import { CompanyAnnotation } from '../models/Model/CompanyAnnotation';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import { useAppSelector } from '../store/hooks';
import authProvider from '../utilities/authProvider';
import { BaseViewModel } from './_BaseViewModel';
import { basicVMFunctions } from './_BasicVMFunctions';
import { INewViewModel, IViewModel, IViewModelFuncCallbacks, IViewModelFuncOverrides, ModelFromEntity } from './_viewModel.interfaces';
import { isNilOrEmpty } from '../utilities/utils';

export interface ICompanyAnnotationViewModel extends INewViewModel<CompanyAnnotation> {}

const apiFuncs = {
	read: api.companyAnnotation.getByIdAsync,
	update: api.companyAnnotation.updateAsync,
	delete: api.companyAnnotation.deleteByIdAsync,
};

function generateModelInstance(representativeId?: string, fieldDefinition?: FieldDefinition[]) {
	const companyAnnotation = new CompanyAnnotation();
	const model = basicVMFunctions.createModelFromEntity<CompanyAnnotation>(companyAnnotation, fieldDefinition);

	model.createdBy_Id.value = authProvider.getUserId() ?? null;

	if (representativeId) {
		model.createdBy_Id.value = representativeId;
	}

	return model;
}

type CompanyAnnotationModel = ModelFromEntity<CompanyAnnotation>;

export function useCompanyAnnotationViewModel(
	id?: string,
	funcCallbacks?: IViewModelFuncCallbacks<CompanyAnnotation>,
	funcOverrides?: IViewModelFuncOverrides<CompanyAnnotation>
): ICompanyAnnotationViewModel {
	const validator = useEntityInputValidator(EntityType.companyAnnotation);

	const allFieldDefinitions = useAppSelector((state) => state.globals.fieldDefinitions ?? []);
	const companyAnnotationFieldDefinitions = useMemo(
		() => basicVMFunctions.filterFieldDefinitionsByEntityType_Name(allFieldDefinitions, 'CompanyAnnotation'),
		[allFieldDefinitions]
	);

	const [model, setModel] = useState<CompanyAnnotationModel>(generateModelInstance(undefined, companyAnnotationFieldDefinitions));
	const [unchangedModel, setUnchangedModel] = useState<CompanyAnnotationModel>(generateModelInstance(undefined, companyAnnotationFieldDefinitions));

	const isValid = useMemo(() => basicVMFunctions.checkIfModelIsValid(model), [model]);

	const hasChanges = useMemo(() => JSON.stringify(model) !== JSON.stringify(unchangedModel) || isNilOrEmpty(model.id.value), [model, unchangedModel]);
	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const setModelPropertyValue = useCallback(
		(propertyName: keyof CompanyAnnotation) => (value) =>
			basicVMFunctions.setModelPropertyValue<CompanyAnnotation>(propertyName, value, model, setModel, validator, funcCallbacks?.setModelPropertyValue),
		[funcCallbacks?.setModelPropertyValue, model, validator]
	);

	const refreshCompanyAnnotation = useCallback(
		async () =>
			await basicVMFunctions.readEntity<CompanyAnnotation>(
				apiFuncs.read,
				companyAnnotationFieldDefinitions,
				setModel,
				setUnchangedModel,
				id,
				funcCallbacks?.refresh
			),
		[companyAnnotationFieldDefinitions, funcCallbacks?.refresh, id]
	);

	const updateCompanyAnnotation = useCallback(
		async () =>
			await basicVMFunctions.updateEntity<CompanyAnnotation>(
				hasChanges,
				apiFuncs.update,
				model,
				companyAnnotationFieldDefinitions,
				setHasSavedChanges,
				refreshCompanyAnnotation,
				funcCallbacks?.update
			),
		[companyAnnotationFieldDefinitions, funcCallbacks?.update, hasChanges, model, refreshCompanyAnnotation]
	);

	const deleteCompanyAnnotation = useCallback(
		async () => await basicVMFunctions.deleteEntity(apiFuncs.delete, setHasSavedChanges, id, funcCallbacks?.delete),
		[funcCallbacks?.delete, id]
	);

	useEffect(() => {
		refreshCompanyAnnotation();
	}, [refreshCompanyAnnotation]);

	// useEffect(() => {
	// 	basicVMFunctions.validateAllProperties(model, setModel, validator);
	// }, [model, validator]);

	return {
		new: true,
		model,
		fieldDefinitions: companyAnnotationFieldDefinitions,
		isValid,
		hasChanges,
		hasSavedChanges,
		refresh: funcOverrides?.refresh ? funcOverrides.refresh : refreshCompanyAnnotation,
		update: funcOverrides?.update ? () => funcOverrides.update!(model, hasChanges, companyAnnotationFieldDefinitions) : updateCompanyAnnotation,
		delete: funcOverrides?.delete ? funcOverrides.delete : deleteCompanyAnnotation,
		setModelPropertyValue: funcOverrides?.setModelPropertyValue ? funcOverrides.setModelPropertyValue(model, setModel, validator) : setModelPropertyValue,
	};
}
