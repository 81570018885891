import BoundInputRows from '../../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Contact } from '../../../../models/Model/Contact';
import { IEntityFormDetailProps } from '../../entityDetailPopup.interfaces';
import { FormDetail } from '../_FormDetail';
import { useAppDispatch } from '../../../../store/hooks';
import { updateCompanies } from '../../../../store/reducers/CompanySlice';
import { LinkType } from '../../../InputFields/inputField.types';
import { InfoGroup } from '../../../InfoBlocks';
import { getContactFullName } from '../../../../utilities/entityHelpers';
import { CustomFieldEditor } from '../../CustomField/CustomFieldEditor';
import { getObjProps } from '../../../../utilities/utils';
import { useContactViewModel } from '../../../../viewmodels/ContactViewModel';
import { DropdownEntityType } from '../../../InputFields/BoundDropDown/types';
import { basicVMFunctions } from '../../../../viewmodels/_BasicVMFunctions';

interface IContactFormDetailProps extends IEntityFormDetailProps {}

const propertyNames = getObjProps<Contact>();

export const ContactFormDetail = (props: IContactFormDetailProps) => {
	const [id, setId] = useState(props.id);

	const viewModel = useContactViewModel(id, props.parentId, { update: (entity) => basicVMFunctions.createEntityCallback(entity, id, setId) });

	const dispatch = useAppDispatch();

	useEffect(() => {
		setId(props.id);
	}, [props.id]);

	useEffect(() => {
		props.setIsDirty(viewModel.hasChanges);
	}, [props, viewModel.hasChanges]);

	useEffect(() => {
		props.setHasSavedChanges(viewModel.hasSavedChanges);
	}, [props, viewModel.hasSavedChanges]);

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<FormDetail
			vm={viewModel}
			headerText={getContactFullName(basicVMFunctions.createEntityFromModel(viewModel.model))}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName="address-book"
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('connection')}
			>
				<BoundInputRows.PresetDropDownRow
					label={t('company')}
					new
					modelProperty={viewModel.model.company_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.company_Id)}
					DDEntityType={DropdownEntityType.companies}
					readonly
				/>
			</InfoGroup>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('name')}
			>
				<BoundInputRows.TextRow
					label={t('contactAttributes.salutation')}
					new
					modelProperty={viewModel.model.salutation}
					setProperty={viewModel.setModelPropertyValue(propertyNames.salutation)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('contactAttributes.firstName')}
					new
					modelProperty={viewModel.model.firstName}
					setProperty={viewModel.setModelPropertyValue(propertyNames.firstName)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('contactAttributes.surnamePrefixes')}
					new
					modelProperty={viewModel.model.surnamePrefixes}
					setProperty={viewModel.setModelPropertyValue(propertyNames.surnamePrefixes)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('contactAttributes.surname')}
					new
					modelProperty={viewModel.model.surname}
					setProperty={viewModel.setModelPropertyValue(propertyNames.surname)}
					readonly={!props.isEditing}
				/>
			</InfoGroup>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('contactInfo')}
			>
				<BoundInputRows.HyperlinkRow
					label={t('contactAttributes.emailAddress')}
					new
					modelProperty={viewModel.model.emailAddress}
					setProperty={viewModel.setModelPropertyValue(propertyNames.emailAddress)}
					readonly={!props.isEditing}
					type={LinkType.email}
				/>
				<BoundInputRows.HyperlinkRow
					label={t('contactAttributes.mobileNumber')}
					new
					modelProperty={viewModel.model.mobileNumber}
					setProperty={viewModel.setModelPropertyValue(propertyNames.mobileNumber)}
					readonly={!props.isEditing}
					type={LinkType.tel}
				/>
				<BoundInputRows.HyperlinkRow
					label={t('contactAttributes.phoneNumber')}
					new
					modelProperty={viewModel.model.phoneNumber}
					setProperty={viewModel.setModelPropertyValue(propertyNames.phoneNumber)}
					readonly={!props.isEditing}
					type={LinkType.tel}
				/>
				<BoundInputRows.TextRow
					label={t('contactAttributes.function')}
					new
					modelProperty={viewModel.model.function}
					setProperty={viewModel.setModelPropertyValue(propertyNames.function)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.BooleanRow
					label={t('contactAttributes.isActive')}
					new
					modelProperty={viewModel.model.isActive}
					setProperty={viewModel.setModelPropertyValue(propertyNames.isActive)}
					readonly={!props.isEditing}
					noDeleteButton
				/>
			</InfoGroup>

			<CustomFieldEditor
				vm={viewModel}
				editing={props.isEditing}
			/>
		</FormDetail>
	);
};
