import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import useEntityInputValidator from '../hooks/validation/entityInputValidator';
import { Address } from '../models/Model/Address';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import { useAppSelector } from '../store/hooks';
import { basicVMFunctions } from './_BasicVMFunctions';
import { INewViewModel, IViewModel, IViewModelFuncCallbacks, IViewModelFuncOverrides, ModelFromEntity, ViewModelHook } from './_viewModel.interfaces';
import { isNilOrEmpty } from '../utilities/utils';

export interface IAddressViewModel extends INewViewModel<Address> {}

const apiFuncs = {
	read: api.address.getByIdAsync,
	update: api.address.updateAsync,
	delete: api.address.deleteByIdAsync,
};

function generateModelInstance(companyId?: string, fieldDefinitions?: FieldDefinition[]): ModelFromEntity<Address> {
	const address = new Address();
	const model = basicVMFunctions.createModelFromEntity<Address>(address, fieldDefinitions);

	if (companyId) {
		model.company_Id.value = companyId;
	}

	return model;
}

type AddressModel = ModelFromEntity<Address>;

export function useAddressViewModel(
	id?: string,
	companyId?: string,
	funcCallbacks?: IViewModelFuncCallbacks<Address>,
	funcOverrides?: IViewModelFuncOverrides<Address>
): IAddressViewModel {
	const validator = useEntityInputValidator(EntityType.address);

	const allFieldDefinitions = useAppSelector((state) => state.globals.fieldDefinitions ?? []);
	const fieldDefinitions = useMemo(() => basicVMFunctions.filterFieldDefinitionsByEntityType_Name(allFieldDefinitions, 'Address'), [allFieldDefinitions]);

	const [model, setModel] = useState<AddressModel>(generateModelInstance(companyId, fieldDefinitions));
	const [unchangedModel, setUnchangedModel] = useState<AddressModel>(generateModelInstance(companyId, fieldDefinitions));

	const isValid = useMemo(() => basicVMFunctions.checkIfModelIsValid(model), [model]);

	const hasChanges = useMemo(() => JSON.stringify(model) !== JSON.stringify(unchangedModel) || isNilOrEmpty(model.id.value), [model, unchangedModel]);
	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const setModelPropertyValue = useCallback(
		(propertyName: keyof Address) => (value) =>
			basicVMFunctions.setModelPropertyValue<Address>(propertyName, value, model, setModel, validator, funcCallbacks?.setModelPropertyValue),
		[funcCallbacks?.setModelPropertyValue, model, validator]
	);

	const refresh = useCallback(
		async () => await basicVMFunctions.readEntity<Address>(apiFuncs.read, fieldDefinitions, setModel, setUnchangedModel, id, funcCallbacks?.refresh),

		[fieldDefinitions, id, funcCallbacks?.refresh]
	);

	const update = useCallback(
		async () =>
			await basicVMFunctions.updateEntity<Address>(
				hasChanges,
				apiFuncs.update,
				model,
				fieldDefinitions,
				setHasSavedChanges,
				refresh,
				funcCallbacks?.update
			),

		[hasChanges, model, refresh, fieldDefinitions, funcCallbacks?.update]
	);

	const deleteAddress = useCallback(
		async () => await basicVMFunctions.deleteEntity(apiFuncs.delete, setHasSavedChanges, id, funcCallbacks?.delete),

		[funcCallbacks?.delete, id]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	// useEffect(() => {
	// 	basicVMFunctions.validateAllProperties(model, setModel, validator);
	// }, [model, validator]);

	return {
		new: true,
		model,
		fieldDefinitions,
		isValid,
		hasChanges,
		hasSavedChanges,
		refresh: funcOverrides?.refresh ? funcOverrides.refresh : refresh,
		update: funcOverrides?.update ? () => funcOverrides.update!(model, hasChanges, fieldDefinitions) : update,
		delete: funcOverrides?.delete ? funcOverrides.delete : deleteAddress,
		setModelPropertyValue: funcOverrides?.setModelPropertyValue ? funcOverrides.setModelPropertyValue(model, setModel, validator) : setModelPropertyValue,
	};
}
