import BoundInputRows from '../../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Address } from '../../../../models/Model/Address';
import { IEntityFormDetailProps } from '../../entityDetailPopup.interfaces';
import { FormDetail } from '../_FormDetail';
import { useAppDispatch } from '../../../../store/hooks';
import { InfoGroup } from '../../../InfoBlocks';
import { updateCompanies } from '../../../../store/reducers/CompanySlice';
import { CustomFieldEditor } from '../../CustomField/CustomFieldEditor';
import { DropdownEntityType } from '../../../InputFields/BoundDropDown/types';
import { getObjProps } from '../../../../utilities/utils';
import { useAddressViewModel } from '../../../../viewmodels/AddressViewModel';
import { basicVMFunctions } from '../../../../viewmodels/_BasicVMFunctions';

interface IAddressFormDetailProps extends IEntityFormDetailProps {}

const propertyNames = getObjProps<Address>();

export const AddressFormDetail = (props: IAddressFormDetailProps) => {
	const [id, setId] = useState(props.id);

	const viewModel = useAddressViewModel(id, props.parentId, { update: (entity) => basicVMFunctions.createEntityCallback(entity, id, setId) });

	const dispatch = useAppDispatch();

	useEffect(() => {
		setId(props.id);
	}, [props.id]);

	useEffect(() => {
		props.setIsDirty(viewModel.hasChanges);
	}, [props, viewModel.hasChanges]);

	useEffect(() => {
		props.setHasSavedChanges(viewModel.hasSavedChanges);
	}, [props, viewModel.hasSavedChanges]);

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<FormDetail
			vm={viewModel}
			headerText={viewModel.model.street.value}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName="address-book"
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('connection')}
			>
				<BoundInputRows.PresetDropDownRow
					new
					label={t('company')}
					modelProperty={viewModel.model.company_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.company_Id)}
					DDEntityType={DropdownEntityType.companies}
					readonly={props.id !== ''}
				/>
			</InfoGroup>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('address')}
			>
				<BoundInputRows.PresetDropDownRow
					new
					DDEntityType={DropdownEntityType.addressTypes}
					label={t('addressAttributes.addressType')}
					modelProperty={viewModel.model.addressType_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.addressType_Id)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					new
					label={t('addressAttributes.street')}
					modelProperty={viewModel.model.street}
					setProperty={viewModel.setModelPropertyValue(propertyNames.street)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					new
					label={t('addressAttributes.postalCode')}
					modelProperty={viewModel.model.postalCode}
					setProperty={viewModel.setModelPropertyValue(propertyNames.postalCode)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					new
					label={t('addressAttributes.city')}
					modelProperty={viewModel.model.city}
					setProperty={viewModel.setModelPropertyValue(propertyNames.city)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					new
					label={t('addressAttributes.country')}
					modelProperty={viewModel.model.country_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.country_Id)}
					DDEntityType={DropdownEntityType.countries}
					readonly={!props.isEditing}
				/>
			</InfoGroup>
			<CustomFieldEditor
				vm={viewModel}
				editing={props.isEditing}
			/>
		</FormDetail>
	);
};
