import { ReactElement, createElement, useEffect } from 'react';
import { Settings } from '../../../globals/settings';
import { IconCustomType } from '../../IconCustom/IconCustom.definitions';
import { dateHelper } from '../../../utilities/dateHelper';
import { colorHelper } from '../../../utilities/colorHelper';
import { EntityType } from '../../EntityDetailPopup/entityDetailPopup.enums';
import { PrioritizedTaskViewCollectionViewModel } from '../../../viewmodels/Collections/PrioritizedTaskViewCollectionViewModel';
import { ThemeColorType } from '../../../globals/enums';
import { PrioritizedTaskView } from '../../../models/BusinessObjects_View/PrioritizedTaskView';
import { DashboardLane } from '../DashboardLane';
import DashboardListItem, { IDashboardListItemProps } from '../DashboardListItem';
import { t } from 'i18next';

export function DashboardLaneTasks() {
	const cvm = PrioritizedTaskViewCollectionViewModel('dashboard_tasks', false, Settings.PAGINATION_DASHBOARD_TAKE, true);
	const dateNow = new Date();

	const generateElement = (entity: PrioritizedTaskView, clickHandler: any): ReactElement => {
		let iconColor = ThemeColorType.blisssBlue;
		let iconValue = '';
		let dueDate = entity.task_DueDate;

		//const dateDue = value.dueDate ? new Date(value.dueDate) : this.dateNow;
		const titleBotRight = dueDate ? dateHelper.DATE_FORMATTER_SHORT.format(new Date(dueDate)) : '';

		const info = colorHelper.getTaskOverdueInfo(dateNow, dueDate, entity.task_IsCompleted);

		iconColor = info.color;
		iconValue = info.value;

		const properties: IDashboardListItemProps = {
			icon: IconCustomType.DASH_TASK,
			key: entity.task_Id,
			entity: entity,
			id: 0,

			iconColor: iconColor,
			iconTitle: iconValue,

			titleTopLeft: entity.task_Subject,
			titleMidLeft: entity.task_Company_Name ?? '-',
			titleBotLeft: entity.task_AssignedTo_FullName ?? '-',

			titleTopRight: '',
			titleMidRight: '',
			titleBotRight: titleBotRight,

			onListItemClick: clickHandler,
		};

		let block = createElement(DashboardListItem, properties, null);
		return block;
	};

	return (
		<DashboardLane
			icon={IconCustomType.GEN_TASK}
			title={t('tasks')}
			cvm={cvm}
			elementGeneratorFunc={generateElement}
			popupEntityType={EntityType.Task}
		/>
	);
}
