import { IFormHeaderProps } from '../../entityDetailPopup.interfaces';
import { ButtonsGeneral } from './Buttons/_ButtonsGeneral';
import { FormHeaderBase } from './_FormHeaderBase';

export function FormHeaderGeneral(props: IFormHeaderProps) {
	return (
		<FormHeaderBase {...props}>
			<ButtonsGeneral
				vm={props.vm}
				editing={props.editing}
				setEditing={props.setEditing}
				closeDetailPopup={props.closeDetailPopup}
			/>
		</FormHeaderBase>
	);
}
