import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../api/_Executor';
import { EntityType } from '../globals/enums';
import { Appointment } from '../models/Model/Appointment';
import { BaseViewModel } from './_BaseViewModel';
import { INewViewModel, IViewModel, IViewModelFuncCallbacks, IViewModelFuncOverrides, ModelFromEntity } from './_viewModel.interfaces';
import { UserManager } from '../utilities/UserManager';
import { basicVMFunctions } from './_BasicVMFunctions';
import { FieldDefinition } from '../models/Model_Fields/FieldDefinition';
import useEntityInputValidator, { ValidatorObject } from '../hooks/validation/entityInputValidator';
import { useAppSelector } from '../store/hooks';
import { isNilOrEmpty, isNotNilOrEmpty } from '../utilities/utils';
import { Activity } from '../models/Model/Activity';
import { ActivityTypeName } from '../model/_Enums';

export interface IAppointmentViewModel extends INewViewModel<Appointment> {}

async function readAppointment(id: string, includes?: string[]): Promise<Appointment | undefined> {
	const activity = await api.activity.getByIdAsync(id, includes);
	if (isNotNilOrEmpty(activity)) {
		if (activity?.$type === ActivityTypeName.Activity) {
			const appointment = new Appointment();
			for (const propertyName in appointment) {
				appointment[propertyName] = activity[propertyName] ?? appointment[propertyName];
			}
			return appointment;
		}
		return activity as Appointment;
	}
}

const apiFuncs = {
	read: readAppointment,
	update: api.special.updateAppointment,
	delete: api.appointment.deleteByIdAsync,
};

const userManager = new UserManager();

function generateModelInstance(companyId?: string, representativeId?: string, fieldDefinitions?: FieldDefinition[]) {
	const appointment = new Appointment();
	const model = basicVMFunctions.createModelFromEntity<Appointment>(appointment, fieldDefinitions);

	model.$type.value = EntityType.appointment.toString();
	model.assignedTo_Id.value = userManager.getCurrentUserId() ?? null;
	model.isClosed.value = false;
	model.isFollowUp.value = false;
	model.startDateTime.value = new Date();
	model.startDateTime.value.setMinutes(0);
	model.endDateTime.value = new Date();
	model.endDateTime.value.setHours(model.startDateTime.value.getHours() + 1);
	model.endDateTime.value.setMinutes(0);

	if (companyId) {
		model.company_Id.value = companyId;
	}
	if (representativeId) {
		model.assignedTo_Id.value = representativeId;
	}

	return model;
}

type AppointmentModel = ModelFromEntity<Appointment>;

export function useAppointmentViewModel(
	id: string,
	companyId?: string,
	funcCallbacks?: IViewModelFuncCallbacks<Appointment>,
	funcOverrides?: IViewModelFuncOverrides<Appointment>
): IAppointmentViewModel {
	const allFieldDefinitions = useAppSelector((state) => state.globals.fieldDefinitions ?? []);
	const appointmentFieldDefinitions = useMemo(
		() => basicVMFunctions.filterFieldDefinitionsByEntityType_Name(allFieldDefinitions, 'Appointment'),
		[allFieldDefinitions]
	);

	const [model, setModel] = useState<AppointmentModel>(generateModelInstance(companyId, undefined, appointmentFieldDefinitions));
	const [unchangedModel, setUnchangedModel] = useState<AppointmentModel>(generateModelInstance(companyId, undefined, appointmentFieldDefinitions));

	//validation func for ensuring that appointment never ends before it begins
	const checkAppointmentDateTimeRange = useCallback(() => {
		if (model.startDateTime.value && model.endDateTime.value) {
			const start = new Date(model.startDateTime.value);
			const end = new Date(model.endDateTime.value);

			if (start.getTime() > end.getTime()) return false;
		}
		return true;
	}, [model.endDateTime.value, model.startDateTime.value]);

	const validator = useEntityInputValidator(EntityType.appointment, {
		startDateTime: [checkAppointmentDateTimeRange],
		endDateTime: [checkAppointmentDateTimeRange],
	});

	const hasChanges = useMemo(() => JSON.stringify(model) !== JSON.stringify(unchangedModel) || isNilOrEmpty(model.id.value), [model, unchangedModel]);
	const [hasSavedChanges, setHasSavedChanges] = useState(false);

	const isValid = useMemo(() => {
		if (model.$type.value === ActivityTypeName.Activity && !hasChanges) return false;
		return basicVMFunctions.checkIfModelIsValid(model);
	}, [hasChanges, model]);

	const setModelPropertyValue = useCallback(
		(propertyName: keyof Appointment) => (value) =>
			basicVMFunctions.setModelPropertyValue<Appointment>(propertyName, value, model, setModel, validator, funcCallbacks?.setModelPropertyValue),
		[funcCallbacks?.setModelPropertyValue, model, validator]
	);

	const refresh = useCallback(async () => {
		await basicVMFunctions.readEntity<Appointment>(apiFuncs.read, appointmentFieldDefinitions, setModel, setUnchangedModel, id, funcOverrides?.refresh);
	}, [appointmentFieldDefinitions, id, funcOverrides?.refresh]);

	const update = useCallback(
		async () =>
			await basicVMFunctions.updateEntity<Appointment>(
				hasChanges,
				apiFuncs.update,
				model,
				appointmentFieldDefinitions,
				setHasSavedChanges,
				refresh,
				funcCallbacks?.update
			),
		[appointmentFieldDefinitions, funcCallbacks?.update, hasChanges, model, refresh]
	);

	const deleteAppointment = useCallback(
		async () => await basicVMFunctions.deleteEntity(apiFuncs.delete, setHasSavedChanges, id, funcCallbacks?.delete),
		[funcCallbacks?.delete, id]
	);

	useEffect(() => {
		refresh();
	}, [refresh]);

	return {
		new: true,
		model,
		fieldDefinitions: appointmentFieldDefinitions,
		isValid,
		hasChanges,
		hasSavedChanges,
		refresh: funcOverrides?.refresh ? funcOverrides.refresh : refresh,
		update: funcOverrides?.update ? () => funcOverrides.update!(model, hasChanges, appointmentFieldDefinitions) : update,
		delete: funcOverrides?.delete ? funcOverrides.delete : deleteAppointment,
		setModelPropertyValue: funcOverrides?.setModelPropertyValue ? funcOverrides.setModelPropertyValue(model, setModel, validator) : setModelPropertyValue,
	};
}
