import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { DateTimeType } from '../../InputFields/inputField.types';
import { updateCompanies } from '../../../store/reducers/CompanySlice';
import { FormDetail } from './_FormDetail';
import { INewViewModel, ModelFromEntity } from '../../../viewmodels/_viewModel.interfaces';
import { Task } from '../../../models/Model/Task';
import { IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { useTaskViewModel } from '../../../viewmodels/TaskViewModel';
import { FormHeaderTask } from './Headers/FormHeaderTask';
import { InfoGroup } from '../../InfoBlocks';
import { DropdownEntityType } from '../../InputFields/BoundDropDown/types';
import { getObjProps } from '../../../utilities/utils';
import { CustomFieldEditor } from '../CustomField/CustomFieldEditor';
import { basicVMFunctions } from '../../../viewmodels/_BasicVMFunctions';

interface ITaskFormDetailProps extends IEntityFormDetailProps {}

const propertyNames = getObjProps<Task>();

export function TaskFormDetail(props: ITaskFormDetailProps) {
	const [id, setId] = useState(props.id);

	const crudCallbacks = useMemo(() => {
		const additionalCallbacks = {
			update: (entity) => {
				basicVMFunctions.createEntityCallback(entity, id, setId);
			},
		};
		if (props.crudCallbacks) return basicVMFunctions.appendCallbacks(props.crudCallbacks, additionalCallbacks);
		else return additionalCallbacks;
	}, [id, props.crudCallbacks]);

	//TODO: Baseform?
	const viewModel = useTaskViewModel(id, props.parentId, crudCallbacks);

	const dispatch = useAppDispatch();

	useEffect(() => {
		setId(props.id);
	}, [props.id]);

	useEffect(() => {
		props.setIsDirty(viewModel.hasChanges);
	}, [props, viewModel.hasChanges]);

	useEffect(() => {
		props.setHasSavedChanges(viewModel.hasSavedChanges);
	}, [props, viewModel.hasSavedChanges]);

	useEffect(() => {
		dispatch(updateCompanies());
	}, [dispatch]);

	return (
		<FormDetail
			vm={viewModel as INewViewModel<Task>}
			headerText={viewModel.model.subject.value}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName="list-check"
			header={
				<FormHeaderTask
					vm={viewModel as INewViewModel<Task>}
					editing={props.isEditing}
					setEditing={props.setIsEditing}
					closeDetailPopup={props.closeDetailPopup}
				/>
			}
		>
			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('connection')}
			>
				<BoundInputRows.PresetDropDownRow
					new
					label={t('company')}
					modelProperty={viewModel.model.company_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.company_Id)}
					DDEntityType={DropdownEntityType.companies}
					readonly={!props.isEditing}
				/>
			</InfoGroup>

			<InfoGroup
				padding
				open={true}
				visible={true}
				title={t('taskData')}
			>
				<BoundInputRows.TextRow
					new
					label={t('taskAttributes.subject')}
					modelProperty={viewModel.model.subject}
					setProperty={viewModel.setModelPropertyValue(propertyNames.subject)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextareaRow
					new
					label={t('taskAttributes.description')}
					modelProperty={viewModel.model.description}
					setProperty={viewModel.setModelPropertyValue(propertyNames.description)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.DateTimeRow
					new
					label={t('taskAttributes.dueDate')}
					modelProperty={viewModel.model.dueDate}
					setProperty={viewModel.setModelPropertyValue(propertyNames.dueDate)}
					type={DateTimeType.date}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					new
					label={t('representativeAttributes.assignedTo')}
					modelProperty={viewModel.model.assignedTo_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.assignedTo_Id)}
					DDEntityType={DropdownEntityType.activeRepresentatives}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.PresetDropDownRow
					new
					label={t('representativeAttributes.createdBy')}
					modelProperty={viewModel.model.createdBy_Id}
					setProperty={viewModel.setModelPropertyValue(propertyNames.createdBy_Id)}
					DDEntityType={DropdownEntityType.activeRepresentatives}
					readonly
				/>
			</InfoGroup>

			<CustomFieldEditor
				vm={viewModel as INewViewModel<Task>}
				editing={props.isEditing}
			/>
		</FormDetail>
	);
}
