import { useCallback, useMemo } from 'react';
import { useAppSelector } from '../../store/hooks';
import { EntityType, ValidationAttributeType } from '../../globals/enums';
import { IEntityValidation } from '../../models/Validation_EntityValidation/EntityValidation';
import { PropertyValidation } from '../../models/Validation_EntityValidation/PropertyValidation';
import { validationUtility } from '../../utilities/validationUtility';

export type ValidatorFunc = (value: any) => boolean;
export type ValidatorObject = Record<string, ValidatorFunc[]>;

const validationFuncs = {
	[ValidationAttributeType.stringLengthEntityPropertyValidationAttribute]: validationUtility.validateStringLength,
	[ValidationAttributeType.numericRangeEntityPropertyValidationAttribute]: validationUtility.validateNumericRange,
	[ValidationAttributeType.requiredEntityPropertyValidationAttribute]: validationUtility.validateRequiredEntity,
	[ValidationAttributeType.requiredIdEntityPropertyValidationAttribute]: validationUtility.validateRequiredIdEntity,
	[ValidationAttributeType.regexEntityPropertyValidationAttribute]: validationUtility.validateRegex,
	[ValidationAttributeType.dateTimeStringEntityPropertyValidationAttribute]: validationUtility.validateDateTime,
};

export function filterEntityValidationsByEntityType(entityValidations: IEntityValidation[], entityType: EntityType) {
	const filteredValidation = entityValidations.find((entityValidation) => entityValidation.entityType === entityType);

	return filteredValidation;
}

export function convertPropertyValidationsToValidatorObject(propertyValidations?: PropertyValidation[], defaultObject: ValidatorObject = {}) {
	const validatorObject = defaultObject;

	if (propertyValidations)
		propertyValidations.forEach((propertyValidation) => {
			if (propertyValidation.propertyName) {
				const propertyName = propertyValidation.propertyName as string;
				const propertyValidationAttributes = propertyValidation.entityPropertyValidationAttributes;
				if (propertyValidationAttributes) {
					const propertyValidationFuncs = propertyValidationAttributes.map((validationAttr) => {
						const type = validationAttr.$type as string;
						return (value) => validationFuncs[type](propertyValidation, value);
					});

					if (validatorObject[propertyName]) propertyValidationFuncs.push(...validatorObject[propertyName]);

					validatorObject[propertyName] = propertyValidationFuncs;
				}
			}
		});

	return validatorObject as ValidatorObject;
}

export function useEntityInputValidator(entityType: EntityType, validatorObjectSupplement?: ValidatorObject) {
	const allEntityValidations = useAppSelector((state) => state.globals.entityValidation);

	const entityValidation = useMemo(() => filterEntityValidationsByEntityType(allEntityValidations, entityType), [allEntityValidations, entityType]);
	const validatorObject = useMemo(
		() => convertPropertyValidationsToValidatorObject(entityValidation?.propertyValidations ?? undefined, validatorObjectSupplement),
		[entityValidation?.propertyValidations, validatorObjectSupplement]
	);

	const validateProperty = useCallback(
		(propertyName, value): boolean => {
			let isValid = true;
			if (validatorObject && validatorObject[propertyName]) {
				const validatorFuncs = validatorObject[propertyName];
				validatorFuncs.forEach((validatorFunc) => {
					if (!validatorFunc(value)) {
						isValid = false;
						return;
					}
				});
			}
			return isValid;
		},
		[validatorObject]
	);

	return validateProperty;
}

export default useEntityInputValidator;
