import styles from '../../formDetail.module.scss';
import { Fragment, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faSave, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { IButtonsProps } from '../../../entityDetailPopup.interfaces';
import { openAlert, openConfirm } from '../../../../Dialog/dialogSlice';
import { useDispatch } from 'react-redux';
import { INewViewModel } from '../../../../../viewmodels/_viewModel.interfaces';

export function ButtonsGeneral(props: IButtonsProps) {
	const [editing, setEditing] = [props.editing, props.setEditing];
	const dispatch = useDispatch();

	const deleteItem = useCallback(async () => {
		if (props.vm.new) {
			const newVM = props.vm as INewViewModel<any>;
			await newVM.delete();
		} else await props.vm.del();
		props.closeDetailPopup();
	}, [props]);

	const toggleEditing = useCallback(() => {
		setEditing(!editing);
	}, [editing, setEditing]);

	const confirmDelete = useCallback(() => {
		dispatch(
			openConfirm({
				title: 'delete',
				text: 'areYouSureDelete',
				confirmButtons: [
					{
						confirmText: 'delete',
						onClick: async () => await deleteItem(),
					},
				],
			})
		);
	}, [deleteItem, dispatch]);

	let hasChanges = false;
	if (props.vm.new) {
		const newVM = props.vm as INewViewModel<any>;
		hasChanges = newVM.hasChanges;
	} else hasChanges = props.vm.isDirty;

	const updateItem = useCallback(async () => {
		if (!props.vm.isValid || !hasChanges) return;

		const success = await props.vm.update();

		if (!success) {
			dispatch(
				openAlert({
					text: 'anErrorOccurred',
				})
			);
		} else {
			setEditing(false);
		}
	}, [dispatch, hasChanges, props, setEditing]);

	const saveButtonClassName = `${styles.formButton} ${!props.vm.isValid && styles.disabled}`;

	return (
		<Fragment>
			{props.children}
			{props.editing ? (
				<FontAwesomeIcon
					className={saveButtonClassName}
					icon={faSave}
					onClick={props.vm.isValid ? (hasChanges ? async () => await updateItem() : toggleEditing) : undefined}
					size="xl"
				/>
			) : (
				<FontAwesomeIcon
					className={styles.formButton}
					icon={faPenToSquare}
					onClick={toggleEditing}
					size="xl"
				/>
			)}

			{!props.excludeDelete ? (
				<FontAwesomeIcon
					className={styles.formButton}
					icon={faTrashCan}
					size="xl"
					onClick={confirmDelete}
				/>
			) : null}
		</Fragment>
	);
}
