import { useCallback, useMemo, useState } from 'react';
import { INewViewModel, IViewModel } from '../../../viewmodels/_viewModel.interfaces';
import AnnotationEditor, { whenToUseChangeHandler } from '../../EntityListDetail/AnnotationEditor/AnnotationEditor';
import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import { t } from 'i18next';
import { stringJoin } from '../FormDetail/_utility';
import { DropdownEntityType } from '../../InputFields/BoundDropDown/types';
import { IEntityFormDetailProps, IFormHeaderProps } from '../entityDetailPopup.interfaces';
import { FormDetail } from '../FormDetail/_FormDetail';
import { InfoGroup } from '../../InfoBlocks';
import { getObjProps, utcDateToLocaleDateString } from '../../../utilities/utils';
import { FormHeaderBase } from '../FormDetail/Headers/_FormHeaderBase';
import { QuickAppointmentAnnotation } from '../../../models/BusinessObjects_Model/QuickAppointmentAnnotation';
import FormDetailStyles from '../FormDetail/formDetail.module.scss';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../../store/hooks';
import useQuickAnnotationViewModel, { IQuickAnnotationViewModel } from '../../../viewmodels/QuickAnnotationViewModel';
import authProvider from '../../../utilities/authProvider';
import { AppointmentAnnotation } from '../../../models/Model/AppointmentAnnotation';

interface IQuickAnnotionFormDetailProps extends IEntityFormDetailProps {
	vm: IViewModel<QuickAppointmentAnnotation>;
}

const propertyNames = getObjProps<QuickAppointmentAnnotation>();

export function QuickAnnotationForm(props: IQuickAnnotionFormDetailProps) {
	const organizationSettings = useAppSelector((state) => state.globals.organizationSettings);
	const showAppointmentSettings = useMemo(
		() => organizationSettings.quickAnnotation_ShowAppointmentSettings,
		[organizationSettings.quickAnnotation_ShowAppointmentSettings]
	);

	const [formattedDate] = useState(utcDateToLocaleDateString(Date.now()));
	const viewModel = useQuickAnnotationViewModel(props.id);

	return (
		<FormDetail
			vm={viewModel as INewViewModel<QuickAppointmentAnnotation>}
			headerText={viewModel.model.appointmentSubject.value ?? ''}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName='note-sticky'
			header={
				<QuickAnnotationHeader
					vm={viewModel}
					headerText={viewModel.model.appointmentSubject.value ?? ''}
					editing={props.isEditing}
					setEditing={props.setIsEditing}
					closeDetailPopup={props.closeDetailPopup}
					iconName='note-sticky'
				/>
			}
		>
			<AnnotationEditor
				defaultContent={viewModel.model.annotationText.value}
				labels={{ left: formattedDate, middle: t('annotation') }}
				functions={{
					changeHandler: viewModel.setModelPropertyValue(propertyNames.annotationText),
				}}
				whenToUseChangeHandler={whenToUseChangeHandler.change}
				errorMessage={!viewModel.model.annotationText.isValid ? 'error' : undefined}
				editing
			/>
			{showAppointmentSettings && (
				<InfoGroup
					padding
					open={true}
					visible={true}
					title={t('appointmentAnnotationAttributes.appointmentInfoHeaderText')}
				>
					<BoundInputRows.TextRow
						label={t('subject')}
						new
						modelProperty={viewModel.model.appointmentSubject}
						setProperty={viewModel.setModelPropertyValue(propertyNames.appointmentSubject)}
					/>
					<BoundInputRows.PresetDropDownRow
						label={t('appointmentAttributes.appointmentType')}
						new
						modelProperty={viewModel.model.appointmentTypeId}
						setProperty={viewModel.setModelPropertyValue(propertyNames.appointmentTypeId)}
						DDEntityType={DropdownEntityType.appointmentTypes}
					/>
					<BoundInputRows.BooleanRow
						label={t('appointmentAttributes.isRecurring')}
						new
						modelProperty={viewModel.model.isFollowUp}
						setProperty={viewModel.setModelPropertyValue(propertyNames.isFollowUp)}
						noDeleteButton
					/>
				</InfoGroup>
			)}
		</FormDetail>
	);
}

interface IQuickAnnotationHeaderProps extends Omit<IFormHeaderProps, 'vm'> {
	vm: IQuickAnnotationViewModel;
}

export function QuickAnnotationHeader(props: IQuickAnnotationHeaderProps) {
	const vm = props.vm;
	const closeDetailPopup = props.closeDetailPopup;
	const [saveDisabled, disableSave] = useState(false);

	const updateItem = useCallback(async () => {
		if (!vm.isValid || !vm.hasChanges || saveDisabled) return;
		disableSave(true);

		const updateWorked = await vm.update();

		if (updateWorked) closeDetailPopup(true);
	}, [vm, saveDisabled, closeDetailPopup]);

	const saveButtonClassName = `${FormDetailStyles.formButton} ${(!vm.isValid || saveDisabled) && FormDetailStyles.disabled}`;

	return (
		<FormHeaderBase
			{...props}
			vm={vm as INewViewModel<QuickAppointmentAnnotation>}
			headerText={t('appointmentAnnotationAttributes.initialHeaderText')}
		>
			<FontAwesomeIcon
				className={saveButtonClassName}
				icon={faSave}
				onClick={async () => await updateItem()}
				size='xl'
			/>
		</FormHeaderBase>
	);
}
