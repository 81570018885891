import { useEffect, useMemo, useState } from 'react';
import { VisitingFrequency } from '../../../models/Model/VisitingFrequency';
import { IEntityFormDetailProps } from '../entityDetailPopup.interfaces';
import { FormDetail } from './_FormDetail';
import { InfoGroup } from '../../InfoBlocks';
import { t } from 'i18next';
import BoundInputRows from '../../InputFields/BoundInputRows/BoundInputRows';
import useVisitingFrequencyViewModel from '../../../viewmodels/VisitingFrequencyViewModel';
import { getObjProps } from '../../../utilities/utils';
import { basicVMFunctions } from '../../../viewmodels/_BasicVMFunctions';

interface IVisitingFrequencyFormDetailProps extends IEntityFormDetailProps {}

const propertyNames = getObjProps<VisitingFrequency>();

export const VisitingFrequencyFormDetail = (props: IVisitingFrequencyFormDetailProps) => {
	const [id, setId] = useState(props.id);

	const crudCallbacks = useMemo(() => {
		const additionalCallbacks = {
			update: (entity) => {
				basicVMFunctions.createEntityCallback(entity, id, setId);
			},
		};
		if (props.crudCallbacks) return basicVMFunctions.appendCallbacks(props.crudCallbacks, additionalCallbacks);
		else return additionalCallbacks;
	}, [id, props.crudCallbacks]);

	const viewModel = useVisitingFrequencyViewModel(id, crudCallbacks);

	useEffect(() => {
		setId(props.id);
	}, [props.id]);

	useEffect(() => {
		props.setIsDirty(viewModel.hasChanges);
	}, [props, viewModel.hasChanges]);

	useEffect(() => {
		props.setHasSavedChanges(viewModel.hasSavedChanges);
	}, [props, viewModel.hasSavedChanges]);

	const headerText = useMemo(
		() => `${viewModel.model.code.value}: ${viewModel.model.description.value}`,
		[viewModel.model.code.value, viewModel.model.description.value]
	);

	return (
		<FormDetail
			vm={viewModel}
			headerText={headerText}
			editing={props.isEditing}
			setEditing={props.setIsEditing}
			closeDetailPopup={props.closeDetailPopup}
			iconName="wave-square"
		>
			<InfoGroup
				padding
				open
				visible
				title={t('visitingFrequency')}
			>
				<BoundInputRows.TextRow
					label={t('code')}
					new
					modelProperty={viewModel.model.code}
					setProperty={viewModel.setModelPropertyValue(propertyNames.code)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.TextRow
					label={t('description')}
					new
					modelProperty={viewModel.model.description}
					setProperty={viewModel.setModelPropertyValue(propertyNames.description)}
					readonly={!props.isEditing}
				/>
				<BoundInputRows.NumberRow
					label={t('weekSpan')}
					new
					modelProperty={viewModel.model.weekSpan}
					setProperty={viewModel.setModelPropertyValue(propertyNames.weekSpan)}
					readonly={!props.isEditing}
				/>
			</InfoGroup>
		</FormDetail>
	);
};
