import { useMemo } from 'react';
import { EditMethod, IEntityFormHeaderProps } from '../../entityDetailPopup.interfaces';
import { ButtonsCompany } from './Buttons/ButtonsCompany';
import { FormHeaderBase } from './_FormHeaderBase';
import { INewViewModel } from '../../../../viewmodels/_viewModel.interfaces';
import { Company } from '../../../../models/Model/Company';

export interface IEntityFormHeaderCompanyProps extends IEntityFormHeaderProps {
	quickAnnotationClickHandler: () => void;
	editMethod: EditMethod;
}

export function FormHeaderCompany(props: IEntityFormHeaderCompanyProps) {
	const headerText = useMemo(() => {
		if (props.vm.new) {
			const newVM = props.vm as INewViewModel<Company>;
			return newVM.model.name;
		} else return props.vm.getProperty('name');
	}, [props.vm]);

	return (
		<FormHeaderBase
			{...props}
			iconName="city"
			headerText={headerText}
		>
			<ButtonsCompany
				vm={props.vm}
				editing={props.editing}
				setEditing={props.setEditing}
				closeDetailPopup={props.closeDetailPopup}
				quickAnnotationClickHandler={props.quickAnnotationClickHandler}
				editMethod={props.editMethod}
				id={props.id}
			/>
		</FormHeaderBase>
	);
}
